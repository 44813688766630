import React from 'react';
import queryString from 'query-string';

import './styles.scss';
import Logo from 'assets/logo-white.png';
import {IS_NORMING_VERSION} from 'constants/index';
import strings from '../../utils/strings';
const parsedSearch = queryString.parse(window.location.search);

const error = parsedSearch.error;
const authSuccess = parsedSearch.authSuccess;
function IsValidJSONString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
class LandingPage extends React.Component {
	constructor() {
		super();

		this.state = {
			testKey: '',
		};
	}

	componentDidMount() {
		if (authSuccess) {
			let data = atob(
				decodeURIComponent(
					window.location.search.substr(
						window.location.search.indexOf('=') + 1
					)
				)
			);
			if (data && IsValidJSONString(data)) {
				return new Promise((resolve, reject) => {
					data = JSON.parse(data);

					if (data.authError) {
						reject({message: 'There was an error logging you in'});
						return;
					}

					if (
						 (data && data.action === 'auth' && data.result === 'OK') 
					) {
						localStorage.setItem('authtoken-screening', data.authToken);

						window.location.href = `/test`;
						resolve();
						return;
					}
					reject({message: `Kunne ikke logge inn!`});
				});
			} else {
				return;
			}
		}
	}

	render() {
		const {testKey} = this.state;

		return (
			<div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="Literate Logo"
						/>
					</h1>

					<div className="right">
						<a className="lit-link" href="http://admin.literate.no">
							Administrator
						</a>
					</div>
				</header>

				{IS_NORMING_VERSION && (
					<>
						<h1 className="lit-landing-page-title">
							Barn Screening Test
						</h1>
						<p className="lit-landing-page-subtitle">
							NORMERINGSVERSJON
						</p>
					</>
				)}
				{!IS_NORMING_VERSION && (
					<>
						<h1 className="lit-page-title">
							Screeningtest Barn (Dysmate C)
						</h1>
					</>
				)}
				<p className="lit-page-description">
					Skriv inn din testnøkkel i feltet under
				</p>
				<div className="lit-wrapper--article">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							window.location.href = `/login?key=${testKey}`;
						}}
					>
						<div className="lit-input inline">
							<label className="lit-input__label tiny">
							Din testnøkkel:
							</label>
							<input
								className="lit-input__field"
								onChange={(event) => {
									const value = event.target.value.toUpperCase();
									this.setState({
										testKey: value,
									});
								}}
								placeholder="F.eks 2D84FCA5"
								required
								type="text"
								value={testKey}
							/>
							<button className="lit-btn bg-secondary">
								Start
							</button>
						</div>
						{Boolean(error) && (
							<span>En gyldig nøkkel kreves for å logge inn</span>
						)}
					</form>
				</div>
			</div>
		);
	}
}

export default LandingPage;

import React from 'react';
import {connect} from 'react-redux';

import strings from 'utils/strings';

import {
	goToNextStep,
	setTestStepAnswers,
	setIsSendingTestResultsToFalse,
} from 'actions/index';

import './styles.scss';

import {TEST_STEPS} from 'constants/index';

import Intro from './Intro';
import Test from './Test';

const subSteps = [Intro, Test];

class Orddiktat extends React.Component {
	constructor(props) {
		super(props);

		this.handleNextSubstep = this.handleNextSubstep.bind(this);

		this.state = {
			currentSubstepIndex: 0,
			isSubmitting: false,
		};

		this.audioRefs = {};
	}

	handleNextSubstep(results) {
		const {currentSubstepIndex} = this.state;
		const {candidateGrade} = this.props;

		const {
			onGoToNextStep,
			onSetTestStepAnswers,
			onSetIsSendingTestResultsToFalse,
		} = this.props;

		const isLastStep = currentSubstepIndex === subSteps.length - 1;

		if (isLastStep) {
			const resultKeys = Object.keys(results);
			const formattedResults = resultKeys.map((id) => {
				const value = results[id];
				return {
					id,
					value,
				};
			});
			onSetTestStepAnswers(
				TEST_STEPS.ORDDIKTAT,
				formattedResults,
				candidateGrade
			)
				.then(() => {
					if (!this.state.isSubmitting) {
						this.setState({isSubmitting: true});
						onSetIsSendingTestResultsToFalse();
						onGoToNextStep();
					}
				})
				.catch(() => {
					this.setState({isSubmitting: false});
				});
		} else {
			this.setState({
				currentSubstepIndex: currentSubstepIndex + 1,
			});
			setTimeout(() => {
				window.scrollTo(0, 0);
			});
		}
	}

	render() {
		const {currentSubstepIndex} = this.state;

		const CurrentSubStep = subSteps[currentSubstepIndex];

		return (
			<div>
				<h1 className="lit-page-title">{strings.orddiktat}</h1>
				<CurrentSubStep
					onNextStep={this.handleNextSubstep}
					refs={this.audioRefs}
					candidateGrade={this.props.candidateGrade}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {candidateGrade} = state;
	return {
		candidateGrade,
	};
};

const mapDispatchToProps = {
	onGoToNextStep: goToNextStep,
	onSetTestStepAnswers: setTestStepAnswers,
	onSetIsSendingTestResultsToFalse: setIsSendingTestResultsToFalse,
};

export default connect(mapStateToProps, mapDispatchToProps)(Orddiktat);

import audio1 from 'assets/sounds/nn/diktat/masse.mp3';
import audio2 from 'assets/sounds/nn/diktat/fly.mp3';
import audio3 from 'assets/sounds/nn/diktat/dra.mp3';
import audio4 from 'assets/sounds/nn/diktat/kråka.mp3';
import audio5 from 'assets/sounds/nn/diktat/mjølk.mp3';
import audio6 from 'assets/sounds/nn/diktat/tær.mp3';
import audio7 from 'assets/sounds/nn/diktat/rikke.mp3';
import audio8 from 'assets/sounds/nn/diktat/gret.mp3';
import audio9 from 'assets/sounds/nn/diktat/bake.mp3';
import audio10 from 'assets/sounds/nn/diktat/rekne.mp3';
import audio11 from 'assets/sounds/nn/diktat/hyle.mp3';
import audio12 from 'assets/sounds/nn/diktat/takk.mp3';
import audio13 from 'assets/sounds/nn/diktat/korleis.mp3';
import audio14 from 'assets/sounds/nn/diktat/verda.mp3';
import audio15 from 'assets/sounds/nn/diktat/ekkelt.mp3';
import audio16 from 'assets/sounds/nn/diktat/magisk.mp3';
import audio17 from 'assets/sounds/nn/diktat/ski.mp3';
import audio18 from 'assets/sounds/nn/diktat/leksefri.mp3';
import audio19 from 'assets/sounds/nn/diktat/historier.mp3';
import audio20 from 'assets/sounds/nn/diktat/flokk.mp3';
import audio21 from 'assets/sounds/nn/diktat/senger.mp3';
import audio22 from 'assets/sounds/nn/diktat/kanskje.mp3';
import audio23 from 'assets/sounds/nn/diktat/sjokolade.mp3';
import audio24 from 'assets/sounds/nn/diktat/hjul.mp3';
import audio25 from 'assets/sounds/nn/diktat/rundt.mp3';
const audioFiles = {
	1: audio1,
	2: audio2,
	3: audio3,
	4: audio4,
	5: audio5,
	6: audio6,
	7: audio7,
	8: audio8,
	9: audio9,
	10: audio10,
	11: audio11,
	12: audio12,
	13: audio13,
	14: audio14,
	15: audio15,
	16: audio16,
	17: audio17,
	18: audio18,
	19: audio19,
	20: audio20,
	21: audio21,
	22: audio22,
	23: audio23,
	24: audio24,
	25: audio25,
};

export default audioFiles;

import audio1 from '../assets/no/wordsAudioUpper/Linje.m4a';
import audio2 from '../assets/no/wordsAudioUpper/ujevn.m4a';
import audio3 from '../assets/no/wordsAudioUpper/fortelling.m4a';
import audio4 from '../assets/no/wordsAudioUpper/strek.m4a';
import audio5 from '../assets/no/wordsAudioUpper/vei.m4a';
import audio6 from '../assets/no/wordsAudioUpper/Boye.m4a';
import audio7 from '../assets/no/wordsAudioUpper/hoppe.m4a';
import audio8 from '../assets/no/wordsAudioUpper/sloss.m4a';
import audio9 from '../assets/no/wordsAudioUpper/kaste.m4a';
import audio10 from '../assets/no/wordsAudioUpper/vri.m4a';

import audio11 from '../assets/no/wordsAudioUpper/addisjon.m4a';
import audio12 from '../assets/no/wordsAudioUpper/trekke_fra.m4a';
import audio13 from '../assets/no/wordsAudioUpper/gange.m4a';
import audio14 from '../assets/no/wordsAudioUpper/legge_sammen.m4a';
import audio15 from '../assets/no/wordsAudioUpper/dele.m4a';
import audio16 from '../assets/no/wordsAudioUpper/Hyppig.m4a';
import audio17 from '../assets/no/wordsAudioUpper/ofte.m4a';
import audio18 from '../assets/no/wordsAudioUpper/ukonsentrert.m4a';
import audio19 from '../assets/no/wordsAudioUpper/sjelden.m4a';
import audio20 from '../assets/no/wordsAudioUpper/vanskelig.m4a';
import audio21 from '../assets/no/wordsAudioUpper/Termometer.m4a';
import audio22 from '../assets/no/wordsAudioUpper/linjal.m4a';
import audio23 from '../assets/no/wordsAudioUpper/kalkulator.m4a';
import audio24 from '../assets/no/wordsAudioUpper/vektskal.m4a';
import audio25 from '../assets/no/wordsAudioUpper/gradestokk.m4a';

import audio26 from '../assets/no/wordsAudioUpper/Arve.m4a';
import audio27 from '../assets/no/wordsAudioUpper/male.m4a';
import audio28 from '../assets/no/wordsAudioUpper/hytte.m4a';
import audio29 from '../assets/no/wordsAudioUpper/overta.m4a';
import audio30 from '../assets/no/wordsAudioUpper/gjødsel.m4a';

import audio31 from '../assets/no/wordsAudioUpper/Bestemme.m4a';
import audio32 from '../assets/no/wordsAudioUpper/egenradig.m4a';
import audio33 from '../assets/no/wordsAudioUpper/avgjore.m4a';
import audio34 from '../assets/no/wordsAudioUpper/forstyrre.m4a';
import audio35 from '../assets/no/wordsAudioUpper/eie.m4a';

import audio36 from '../assets/no/wordsAudioUpper/Rakett.m4a';
import audio37 from '../assets/no/wordsAudioUpper/snar.m4a';
import audio38 from '../assets/no/wordsAudioUpper/nyttarsaften.m4a';
import audio39 from '../assets/no/wordsAudioUpper/romskip.m4a';
import audio40 from '../assets/no/wordsAudioUpper/stjerne.m4a';

import audio41 from '../assets/no/wordsAudioUpper/Diskriminere.m4a';
import audio42 from '../assets/no/wordsAudioUpper/forskjellsbehandle.m4a';
import audio43 from '../assets/no/wordsAudioUpper/invitere.m4a';
import audio44 from '../assets/no/wordsAudioUpper/planlegge.m4a';
import audio45 from '../assets/no/wordsAudioUpper/angripe.m4a';

import audio46 from '../assets/no/wordsAudioUpper/Lunken.m4a';
import audio47 from '../assets/no/wordsAudioUpper/iskald.m4a';
import audio48 from '../assets/no/wordsAudioUpper/vann.m4a';
import audio49 from '../assets/no/wordsAudioUpper/botte.m4a';
import audio50 from '../assets/no/wordsAudioUpper/varm.m4a';

import audio51 from '../assets/no/wordsAudioUpper/Infeksjon.m4a';
import audio52 from '../assets/no/wordsAudioUpper/skade.m4a';
import audio53 from '../assets/no/wordsAudioUpper/betennelse.m4a';
import audio54 from '../assets/no/wordsAudioUpper/feber.m4a';
import audio55 from '../assets/no/wordsAudioUpper/avdeling.m4a';

import audio56 from '../assets/no/wordsAudioUpper/bevege.m4a';
import audio57 from '../assets/no/wordsAudioUpper/flytte.m4a';
import audio58 from '../assets/no/wordsAudioUpper/ledd.m4a';
import audio59 from '../assets/no/wordsAudioUpper/soke.m4a';
import audio60 from '../assets/no/wordsAudioUpper/avslutte.m4a';

import audio61 from '../assets/no/wordsAudioUpper/Urinere.m4a';
import audio62 from '../assets/no/wordsAudioUpper/speile.m4a';
import audio63 from '../assets/no/wordsAudioUpper/tisse.m4a';
import audio64 from '../assets/no/wordsAudioUpper/stille_klokka.m4a';
import audio65 from '../assets/no/wordsAudioUpper/mestre.m4a';

import audio66 from '../assets/no/wordsAudioUpper/Oppfatte.m4a';
import audio67 from '../assets/no/wordsAudioUpper/krangle.m4a';
import audio68 from '../assets/no/wordsAudioUpper/misforsta.m4a';
import audio69 from '../assets/no/wordsAudioUpper/innrømme.m4a';
import audio70 from '../assets/no/wordsAudioUpper/forsta.m4a';

import audio71 from '../assets/no/wordsAudioUpper/Drivstoff.m4a';
import audio72 from '../assets/no/wordsAudioUpper/forurensning.m4a';
import audio73 from '../assets/no/wordsAudioUpper/brennstoffer.m4a';
import audio74 from '../assets/no/wordsAudioUpper/klima.m4a';
import audio75 from '../assets/no/wordsAudioUpper/varme.m4a';

import audio76 from '../assets/no/wordsAudioUpper/Skeptisk.m4a';
import audio77 from '../assets/no/wordsAudioUpper/frustrert.m4a';
import audio78 from '../assets/no/wordsAudioUpper/lei_seg.m4a';
import audio79 from '../assets/no/wordsAudioUpper/mistenksom.m4a';
import audio80 from '../assets/no/wordsAudioUpper/irritert.m4a';

import audio81 from '../assets/no/wordsAudioUpper/Reptil.m4a';
import audio82 from '../assets/no/wordsAudioUpper/toalett.m4a';
import audio83 from '../assets/no/wordsAudioUpper/krypdyr.m4a';
import audio84 from '../assets/no/wordsAudioUpper/medisin.m4a';
import audio85 from '../assets/no/wordsAudioUpper/gammel.m4a';

import audio86 from '../assets/no/wordsAudioUpper/Pasta.m4a';
import audio87 from '../assets/no/wordsAudioUpper/hevde.m4a';
import audio88 from '../assets/no/wordsAudioUpper/mase.m4a';
import audio89 from '../assets/no/wordsAudioUpper/forby.m4a';
import audio90 from '../assets/no/wordsAudioUpper/engasjere_seg.m4a';
const audioFilesObj = [
	{
		mainWord: audio1,
		potentialSynonyms: [
			{synonym: audio2},
			{synonym: audio3},
			{synonym: audio4},
			{synonym: audio5},
		],
	},
	{
		mainWord: audio6,
		potentialSynonyms: [
			{synonym: audio7},
			{synonym: audio8},
			{synonym: audio9},
			{synonym: audio10},
		],
	},
	{
		mainWord: audio11,
		potentialSynonyms: [
			{synonym: audio12},
			{synonym: audio13},
			{synonym: audio14},
			{synonym: audio15},
		],
	},
	{
		mainWord: audio16,
		potentialSynonyms: [
			{synonym: audio17},
			{synonym: audio18},
			{synonym: audio19},
			{synonym: audio20},
		],
	},
	{
		mainWord: audio21,
		potentialSynonyms: [
			{synonym: audio22},
			{synonym: audio23},
			{synonym: audio24},
			{synonym: audio25},
		],
	},
	{
		mainWord: audio26,
		potentialSynonyms: [
			{synonym: audio27},
			{synonym: audio28},
			{synonym: audio29},
			{synonym: audio30},
		],
	},
	{
		mainWord: audio31,
		potentialSynonyms: [
			{synonym: audio32},
			{synonym: audio33},
			{synonym: audio34},
			{synonym: audio35},
		],
	},
	{
		mainWord: audio36,
		potentialSynonyms: [
			{synonym: audio37},
			{synonym: audio38},
			{synonym: audio39},
			{synonym: audio40},
		],
	},
	{
		mainWord: audio41,
		potentialSynonyms: [
			{synonym: audio42},
			{synonym: audio43},
			{synonym: audio44},
			{synonym: audio45},
		],
	},
	{
		mainWord: audio46,
		potentialSynonyms: [
			{synonym: audio47},
			{synonym: audio48},
			{synonym: audio49},
			{synonym: audio50},
		],
	},
	{
		mainWord: audio51,
		potentialSynonyms: [
			{synonym: audio52},
			{synonym: audio53},
			{synonym: audio54},
			{synonym: audio55},
		],
	},
	{
		mainWord: audio56,
		potentialSynonyms: [
			{synonym: audio57},
			{synonym: audio58},
			{synonym: audio59},
			{synonym: audio60},
		],
	},
	{
		mainWord: audio61,
		potentialSynonyms: [
			{synonym: audio62},
			{synonym: audio63},
			{synonym: audio64},
			{synonym: audio65},
		],
	},
	{
		mainWord: audio66,
		potentialSynonyms: [
			{synonym: audio67},
			{synonym: audio68},
			{synonym: audio69},
			{synonym: audio70},
		],
	},
	{
		mainWord: audio71,
		potentialSynonyms: [
			{synonym: audio72},
			{synonym: audio73},
			{synonym: audio74},
			{synonym: audio75},
		],
	},
	{
		mainWord: audio76,
		potentialSynonyms: [
			{synonym: audio77},
			{synonym: audio78},
			{synonym: audio79},
			{synonym: audio80},
		],
	},
	{
		mainWord: audio81,
		potentialSynonyms: [
			{synonym: audio82},
			{synonym: audio83},
			{synonym: audio84},
			{synonym: audio85},
		],
	},
	{
		mainWord: audio86,
		potentialSynonyms: [
			{synonym: audio87},
			{synonym: audio88},
			{synonym: audio89},
			{synonym: audio90},
		],
	},
];

export default audioFilesObj;

import audio1 from '../assets/no/wordsAudio/Sliten.mp3';
import audio2 from '../assets/no/wordsAudio/stor.mp3';
import audio3 from '../assets/no/wordsAudio/lat.mp3';
import audio4 from '../assets/no/wordsAudio/trøtt.mp3';
import audio5 from '../assets/no/wordsAudio/vaken.mp3';
import audio6 from '../assets/no/wordsAudio/Bikkje.mp3';
import audio7 from '../assets/no/wordsAudio/hund.mp3';
import audio8 from '../assets/no/wordsAudio/kaldt.m4a';
import audio9 from '../assets/no/wordsAudio/band.mp3';
import audio10 from '../assets/no/wordsAudio/falle.m4a';

import audio11 from '../assets/no/wordsAudio/Hurtig.m4a';
import audio12 from '../assets/no/wordsAudio/knele.m4a';
import audio13 from '../assets/no/wordsAudio/rask.m4a';
import audio14 from '../assets/no/wordsAudio/bevege.mp3';
import audio15 from '../assets/no/wordsAudio/teste.m4a';

import audio16 from '../assets/no/wordsAudio/Mikse.m4a';
import audio17 from '../assets/no/wordsAudio/blande.mp3';
import audio18 from '../assets/no/wordsAudio/trikse.m4a';
import audio19 from '../assets/no/wordsAudio/bake.mp3';
import audio20 from '../assets/no/wordsAudio/tøyse.m4a';

import audio21 from '../assets/no/wordsAudio/Engstelig.m4a';
import audio22 from '../assets/no/wordsAudio/glad.m4a';
import audio23 from '../assets/no/wordsAudio/trist.m4a';
import audio24 from '../assets/no/wordsAudio/sur.m4a';
import audio25 from '../assets/no/wordsAudio/redd.m4a';

import audio26 from '../assets/no/wordsAudio/Mumle.m4a';
import audio27 from '../assets/no/wordsAudio/flue.m4a';
import audio28 from '../assets/no/wordsAudio/korn.m4a';
import audio29 from '../assets/no/wordsAudio/snakke_lavt.m4a';
import audio30 from '../assets/no/wordsAudio/krype.m4a';

import audio31 from '../assets/no/wordsAudio/Næring.m4a';
import audio32 from '../assets/no/wordsAudio/tygge.m4a';
import audio33 from '../assets/no/wordsAudio/føde.m4a';
import audio34 from '../assets/no/wordsAudio/komme_nærmere.m4a';
import audio35 from '../assets/no/wordsAudio/vassen.m4a';

import audio36 from '../assets/no/wordsAudio/Naske.m4a';
import audio37 from '../assets/no/wordsAudio/stjele.m4a';
import audio38 from '../assets/no/wordsAudio/tyv.m4a';
import audio39 from '../assets/no/wordsAudio/hente.m4a';
import audio40 from '../assets/no/wordsAudio/lyge.m4a';

import audio41 from '../assets/no/wordsAudio/Pollen.m4a';
import audio42 from '../assets/no/wordsAudio/papegøye.m4a';
import audio43 from '../assets/no/wordsAudio/stein.m4a';
import audio44 from '../assets/no/wordsAudio/oppløsning.m4a';
import audio45 from '../assets/no/wordsAudio/blomsterstøv.mp3';

import audio46 from '../assets/no/wordsAudio/skip.m4a';
import audio47 from '../assets/no/wordsAudio/kaptein.m4a';
import audio48 from '../assets/no/wordsAudio/hoppe.m4a';
import audio49 from '../assets/no/wordsAudio/boat.mp3';
import audio50 from '../assets/no/wordsAudio/tau.m4a';

import audio51 from '../assets/no/wordsAudio/Avfall.mp3';
import audio52 from '../assets/no/wordsAudio/søppel.m4a';
import audio53 from '../assets/no/wordsAudio/trapp.m4a';
import audio54 from '../assets/no/wordsAudio/drape.m4a';
import audio55 from '../assets/no/wordsAudio/borte.m4a';

import audio56 from '../assets/no/wordsAudio/Pen.m4a';
import audio57 from '../assets/no/wordsAudio/blyant.m4a';
import audio58 from '../assets/no/wordsAudio/vakker.m4a';
import audio59 from '../assets/no/wordsAudio/liten.m4a';
import audio60 from '../assets/no/wordsAudio/glitter.m4a';

import audio61 from '../assets/no/wordsAudio/Usann.m4a';
import audio62 from '../assets/no/wordsAudio/ærlig.mp3';
import audio63 from '../assets/no/wordsAudio/fryktelig.m4a';
import audio64 from '../assets/no/wordsAudio/hemmelig.m4a';
import audio65 from '../assets/no/wordsAudio/løgn.m4a';

import audio66 from '../assets/no/wordsAudio/Plage.m4a';
import audio67 from '../assets/no/wordsAudio/klær.m4a';
import audio68 from '../assets/no/wordsAudio/dø.m4a';
import audio69 from '../assets/no/wordsAudio/pine.m4a';
import audio70 from '../assets/no/wordsAudio/stikke.m4a';

import audio71 from '../assets/no/wordsAudio/Gjemme.m4a';
import audio72 from '../assets/no/wordsAudio/skjule.m4a';
import audio73 from '../assets/no/wordsAudio/låse.m4a';
import audio74 from '../assets/no/wordsAudio/hus.m4a';
import audio75 from '../assets/no/wordsAudio/lete.m4a';
import audio76 from '../assets/no/wordsAudio/Brygge.m4a';
import audio77 from '../assets/no/wordsAudio/snekre.m4a';
import audio78 from '../assets/no/wordsAudio/fjøs.m4a';
import audio79 from '../assets/no/wordsAudio/bo.m4a';
import audio80 from '../assets/no/wordsAudio/kai.m4a';
import audio81 from '../assets/no/wordsAudio/Nøyaktig.m4a';
import audio82 from '../assets/no/wordsAudio/grusom.m4a';
import audio83 from '../assets/no/wordsAudio/måling.m4a';
import audio84 from '../assets/no/wordsAudio/presis.m4a';
import audio85 from '../assets/no/wordsAudio/kjedelig.m4a';
import audio86 from '../assets/no/wordsAudio/Lydløs.m4a';
import audio87 from '../assets/no/wordsAudio/bråkete.m4a';
import audio88 from '../assets/no/wordsAudio/stille.m4a';
import audio89 from '../assets/no/wordsAudio/tunghørt.m4a';
import audio90 from '../assets/no/wordsAudio/forbauset.m4a';

const audioFilesObj = [
	{
		mainWord: audio1,
		potentialSynonyms: [
			{synonym: audio2},
			{synonym: audio3},
			{synonym: audio4},
			{synonym: audio5},
		],
	},
	{
		mainWord: audio6,
		potentialSynonyms: [
			{synonym: audio7},
			{synonym: audio8},
			{synonym: audio9},
			{synonym: audio10},
		],
	},
	{
		mainWord: audio11,
		potentialSynonyms: [
			{synonym: audio12},
			{synonym: audio13},
			{synonym: audio14},
			{synonym: audio15},
		],
	},
	{
		mainWord: audio16,
		potentialSynonyms: [
			{synonym: audio17},
			{synonym: audio18},
			{synonym: audio19},
			{synonym: audio20},
		],
	},
	{
		mainWord: audio21,
		potentialSynonyms: [
			{synonym: audio22},
			{synonym: audio23},
			{synonym: audio24},
			{synonym: audio25},
		],
	},
	{
		mainWord: audio26,
		potentialSynonyms: [
			{synonym: audio27},
			{synonym: audio28},
			{synonym: audio29},
			{synonym: audio30},
		],
	},
	{
		mainWord: audio31,
		potentialSynonyms: [
			{synonym: audio32},
			{synonym: audio33},
			{synonym: audio34},
			{synonym: audio35},
		],
	},
	{
		mainWord: audio36,
		potentialSynonyms: [
			{synonym: audio37},
			{synonym: audio38},
			{synonym: audio39},
			{synonym: audio40},
		],
	},
	{
		mainWord: audio41,
		potentialSynonyms: [
			{synonym: audio42},
			{synonym: audio43},
			{synonym: audio44},
			{synonym: audio45},
		],
	},
	{
		mainWord: audio46,
		potentialSynonyms: [
			{synonym: audio47},
			{synonym: audio48},
			{synonym: audio49},
			{synonym: audio50},
		],
	},
	{
		mainWord: audio51,
		potentialSynonyms: [
			{synonym: audio52},
			{synonym: audio53},
			{synonym: audio54},
			{synonym: audio55},
		],
	},
	{
		mainWord: audio56,
		potentialSynonyms: [
			{synonym: audio57},
			{synonym: audio58},
			{synonym: audio59},
			{synonym: audio60},
		],
	},
	{
		mainWord: audio61,
		potentialSynonyms: [
			{synonym: audio62},
			{synonym: audio63},
			{synonym: audio64},
			{synonym: audio65},
		],
	},
	{
		mainWord: audio66,
		potentialSynonyms: [
			{synonym: audio67},
			{synonym: audio68},
			{synonym: audio69},
			{synonym: audio70},
		],
	},
	{
		mainWord: audio71,
		potentialSynonyms: [
			{synonym: audio72},
			{synonym: audio73},
			{synonym: audio74},
			{synonym: audio75},
		],
	},
	{
		mainWord: audio76,
		potentialSynonyms: [
			{synonym: audio77},
			{synonym: audio78},
			{synonym: audio79},
			{synonym: audio80},
		],
	},
	{
		mainWord: audio81,
		potentialSynonyms: [
			{synonym: audio82},
			{synonym: audio83},
			{synonym: audio84},
			{synonym: audio85},
		],
	},
	{
		mainWord: audio86,
		potentialSynonyms: [
			{synonym: audio87},
			{synonym: audio88},
			{synonym: audio89},
			{synonym: audio90},
		],
	},
];

export default audioFilesObj;

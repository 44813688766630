import React from 'react';

import './styles.scss';

import {updateObject} from 'utils/reducerUtils';
import {authCallback} from 'utils/bankIdLogin';

import sendPin from 'api/sendPin';
import initCustomLogin from 'api/initCustomLogin';

import queryString from 'query-string';
const parsedSearch = queryString.parse(window.top.location.search);
const key = parsedSearch.key;
class CustomLogin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inValidEmail: false,
			hasSendVerifcaiton: true,
			hasResendEmailwithPinCode: false,
			resendEmail: this.props.email,
			emailError: null,
			flowStatus: null,
			pswError: null,
			reqPin: this.props.pinCode,
			testUser: {
				firstName: null,
				lastName: null,
				email: this.props.email ? this.props.email : '',
				key: key ? key : null,
				pin: null,
				origin: 'childScreening',
			},
		};
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleUpdateTestUserEvent =
			this.handleUpdateTestUserEvent.bind(this);
	}

	validateEmail() {
		const email = this.state.testUser.email;

		const value = email;
		if (/^\S+@\S+$$/.test(value)) {
			this.setState({
				inValidEmail: false,
			});
			if (!this.state.hasSendVerifcaiton) {
				this.sendPinToUserEmailAddress();
			}
		} else {
			this.setState({
				inValidEmail: true,
			});
		}
	}

	handleUpdateTestUserEvent(event) {
		const target = event.target;
		const key = target.name;
		const value = target.value;
		this.handleUpdate(key, value);
	}

	handleUpdate(key, value) {
		const {testUser} = this.state;
		this.setState({
			testUser: updateObject(testUser, {
				[key]: value,
			}),
		});
	}
	sendPinToUserEmailAddress(testUser) {
		sendPin(testUser)
			.then((res) => {
				this.setState({
					hasResendEmailwithPinCode: true,
					hasSendVerifcaiton: true,
					reqPin: res.PIN,
					flowStatus: null,
				});
			})
			.catch((error) => {
				this.setState({
					flowStatus: 'Feil testKode eller epost',
				});
			});
	}
	initCustomLogin() {
		if (
			this.state.reqPin &&
			this.state.reqPin.toLowerCase() ===
				this.state.testUser.pin.toLowerCase()
		) {
			initCustomLogin(this.state.testUser)
				.then((results) => {
					results.top = true;
					if (
						results &&
						results.action === 'auth' &&
						results.result === 'OK'
					) {
						window.removeEventListener('message', authCallback);
						localStorage.setItem(
							'authtoken-screening',
							results.authToken
						);

						window.top.location.href = `/test`;
					}
				})
				.catch(() => {
					this.setState({
						flowStatus: 'Ikke gyldig testkode',
					});
				});
		} else {
			this.setState({
				flowStatus: 'Feil pinkode',
			});
		}
	}

	render() {
		return (
			<div className="lit-customLogin">
				{this.state.hasSendVerifcaiton && (
					<div className="lit-customLogin-wrapper">
						<div className="lit-customPin-form-wrapper">
							{this.state.flowStatus && (
								<div className="lit-invalid-status-container">
									<p className="lit-invalid-note">
										{this.state.flowStatus}
									</p>
								</div>
							)}
							{this.props.email &&
								!this.state.hasResendEmailwithPinCode && (
									<div className="lit-pin-instrucitons">
										<p>
											<span>
												Skriv inn <b>PINKODE</b> og
												start testen. Hvis du ikke har
												fått PINKODE fra læreren din, så
												kan du få den tilsendt på den
												registrerte e-post adressen{' '}
												<span style={{color: 'Lime'}}>
													<b>
														<em>
															{this.props.email}
														</em>
													</b>
												</span>
											</span>
										</p>
									</div>
								)}
							{this.props.email &&
								this.state.hasResendEmailwithPinCode && (
									<div className="lit-pin-instrucitons">
										<p>
											<span>
												<span>
													Vi har sendt epost med
													Pinkoden til{' '}
													<span
														style={{color: 'Lime'}}
													>
														<b>
															<em>
																{
																	this.props
																		.email
																}
															</em>
														</b>
													</span>
													.{' '}
												</span>
												Skriv inn <b>PINKODE</b> og
												start testen. Ta kontakt med
												læreren din hvis du ikke har
												fått den tilsendte pinkoden{' '}
											</span>
										</p>
									</div>
								)}
							{!this.props.email && (
								<div className="lit-pin-instrucitons">
									<p>
										<span>
											Skriv din pinkode her. Hvis du ikke
											kjenner pinkoden, spør læreren din
											om det.
											<span>
												{this.props.email
													? this.props.email
													: ''}
											</span>{' '}
										</span>
									</p>
								</div>
							)}
							<form
								className="register-form"
								onSubmit={(event) => {
									event.preventDefault();

									this.initCustomLogin();
								}}
							>
								<div className="lit-customPin-form-wrapper">
									<div className="lit-customPIn-inner-content">
										<div className="lit-compound-pin-fields">
											<div className="lit-label-pin">
												<label
													className="customLogin-pin-lbl"
													htmlFor="pin-kode"
												>
													PINKODE:{' '}
												</label>
												<input
													required
													name="pin"
													id="pin-kode"
													className="customLogin-pin-tf"
													placeholder="skriv her"
													onChange={
														this
															.handleUpdateTestUserEvent
													}
													type="text"
												/>
											</div>
										</div>
									</div>
									<div className="lit-pincode-inlogg">
										<div className="lit-btn-submit-wrapper">
											<button
												className="lit-btn-submit"
												style={{color: 'white'}}
											>
												{'Start Testen'}
											</button>
										</div>
										{this.props.email && (
											<div className="lit-btn-submit-wrapper">
												<button
													className={
														this.state.testUser.pin
															? 'lit-btn-submit-pin__disabled'
															: 'lit-btn-submit-pin'
													}
													value="Cancel"
													disabled={
														this.state.testUser.pin
													}
													onClick={(event) => {
														event.preventDefault();
														this.sendPinToUserEmailAddress(
															{
																email: this
																	.props
																	.email,
																key,
																origin: 'childScreening',
															}
														);
													}}
												>
													{'Send Pinkode'}
												</button>
											</div>
										)}
									</div>
								</div>
							</form>
						</div>
					</div>
				)}
			</div>
		);
	}
}
export default CustomLogin;

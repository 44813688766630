import audio2 from 'assets/sounds/no/2 3 Ordkjedetest-Feil-los-tupp-meg-sa.m4a';
import audio3 from 'assets/sounds/no/Ordkjedetest-Feil-ost-par-vask-jakt.m4a';

const assignments = [
	{
		id: 1,
		text: 'påske last mil fra',
		answer: 'påske last mil fra',
	},
	{
		id: 2,
		text: 'iskattsolhår',
		answer: 'is katt sol hår',
		audio: audio2,
		count: 'andre',
		hasPlayed: false,
	},
	{
		id: 3,
		text: 'ikkehuselgvåt',
		answer: 'ikke hus elg våt',
		audio: audio3,
		count: 'tredje',
		hasPlayed: false,
	},
];
export default assignments;

import getFetchOptions from 'utils/getFetchOptions';

import {ENDPOINT_URL} from 'constants/index';

export default function markKeyAsUsed() {
	return fetch(
		`${ENDPOINT_URL}/test/markkey`,
		getFetchOptions({
			method: 'POST',
		})
	);
}

//TODO-SIMEN REPLACE ALL THESE WITH LOCALIZED SOUNDS

import bananaWrong from 'assets/sounds/no/banana-is-wrong.mp3';
import arrowWrong from 'assets/sounds/no/arrow-is-wrong.mp3';
import tutorial6Sound from 'assets/sounds/no/Bytt-tutorial-6.mp3';
import lockCorrect from 'assets/sounds/no/lock-is-correct.mp3';
import doorWrong from 'assets/sounds/no/door-is-wrong.mp3';

import fireCorrect from 'assets/sounds/no/fire-is-correct.mp3';
import sunWrong from 'assets/sounds/no/sun-is-wrong.mp3';
import jetWrong from 'assets/sounds/no/jet-is-wrong.mp3';
import carWrong from 'assets/sounds/no/car-is-wrong.mp3';
import tutorial3Sound from 'assets/sounds/no/Bytt-tutorial-3.mp3';
import catWrong from 'assets/sounds/no/cat-is-wrong.mp3';
import kombCorrect from 'assets/sounds/no/komb-is-correct.mp3';
import readingWrong from 'assets/sounds/no/lese-is-wrong.mp3';
import lightWrong from 'assets/sounds/no/lys-is-wrong.mp3';
import tutorial1Sound from 'assets/sounds/no/Bytt-tutorial-1.mp3';

import blueImg from '../assets/blue.png';
import skyImg from '../assets/sky.png';
import greenImg from '../assets/green.png';
import yellowImg from '../assets/yellow.png';
import redImg from '../assets/red.png';
import purpleImg from '../assets/purple.png';
import cowImg from '../assets/cow.png';
import bulbImg from '../assets/bulb.png';
import mittenImg from '../assets/mittens.png';
import pencilImg from '../assets/pencil.png';
import puppyImg from '../assets/Hund.PNG';
import lamb from '../assets/Lam.PNG';
import komb from '../assets/Kam.PNG';
import light from '../assets/Lys.PNG';
import reading from '../assets/Lese.PNG';
import cat from '../assets/Katt.PNG';
import boat from '../assets/Båt.PNG';
import car from '../assets/Bil.PNG';
import plane from '../assets/Fly.PNG';
import sun from '../assets/Sol.PNG';
import fire from '../assets/Bål.PNG';
import door from '../assets/Dør.PNG';
import lockIMG from '../assets/Lås.PNG';
import arrow from '../assets/Pil.PNG';
import banana from '../assets/Banan.PNG';

import starterTesten from 'assets/sounds/no/3 13 Tulleord Starter testen.mp3';

export const starterTestenSound = starterTesten;

export const bytteLydenExample = {};

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'Lys',
		},
		{
			word: 'Lese',
		},
		{
			word: 'Katt',
		},
		{
			isCorrect: true,
			word: 'Kam',
		},
	],
};
export const assignment1 = [
	{
		id: 1,
		mainImg: lamb,
		mainSound: tutorial1Sound,
		text:
			'Hvilket ord får du hvis du bytter den første lyden /l/ i lam med lyden /k/? ',
		choices: [
			{
				remark: false,
				word: 'Lys',
				comment: 'Lys er dessverre feil. Prøv på nytt.',
				sound: lightWrong,
				img: light,
			},
			{
				remark: true,
				word: 'Kam',
				comment: `Kam er helt riktig. Når du bytter /l/ i lam med /k/, så blir det nye ordet «kam».`,
				sound: kombCorrect,
				img: komb,
			},
			{
				remark: false,
				word: 'Katt',
				comment: 'Katt er dessverre feil. Prøv på nytt',
				sound: catWrong,
				img: cat,
			},
			{
				remark: false,
				word: 'Lese',
				comment: 'Lese er dessverre feil. Prøv på nytt',
				sound: readingWrong,
				img: reading,
			},
		],
	},
];

export const assignment2 = [
	{
		id: 2,
		text:
			'Hvilket ord får du hvis du bytter den siste lyden /t/ i båt med lyden /l/? ',
		mainImg: boat,
		mainSound: tutorial3Sound,
		choices: [
			{
				remark: false,
				word: 'Bil',
				comment: 'Bil er dessverre feil. Prøv på nytt',
				sound: carWrong,
				img: car,
			},
			{
				remark: false,
				word: 'Fly',
				comment: 'Fly er dessverre feil. Prøv på nytt',
				sound: jetWrong,
				img: plane,
			},
			{
				remark: false,
				word: 'Sol',
				comment: 'Sol er dessverre feil. Prøv på nytt',
				sound: sunWrong,
				img: sun,
			},
			{
				remark: true,
				word: 'Bål',
				comment:
					'Bål er helt riktig. Når du bytter /t/ i båt med /l/ så blir det nye ordet «bål».',
				sound: fireCorrect,
				img: fire,
			},
		],
	},
];

export const assignment3 = [
	{
		id: 3,
		mainImg: light,
		mainSound: tutorial6Sound,
		text:
			'Hvilket ord får du hvis du bytter den midterste lyden /y/ i lys med lyden /å/? ',
		choices: [
			{
				remark: false,
				word: 'Pil',
				comment: 'Pil er dessverre feil. Prøv på nytt',
				sound: arrowWrong,
				img: arrow,
			},
			{
				remark: false,
				word: 'Banan',
				comment: 'Banan er dessverre feil. Prøv på nytt',
				sound: bananaWrong,
				img: banana,
			},
			{
				remark: false,
				word: 'Dør',
				comment: 'Dør er dessverre feil. Prøv på nytt',
				sound: doorWrong,
				img: door,
			},
			{
				remark: true,
				word: 'Lock',
				comment:
					'Lås er helt riktig. Når du bytter /y/ i «lys» med /å/, så blir det nye ordet «lås»',
				sound: lockCorrect,
				img: lockIMG,
			},
		],
	},
];
export const assignment4 = [
	{
		id: 4,
		mainImg: puppyImg,
		choices: [
			{
				remark: true,
				word: 'modil',
				comment: '',
				sound: carWrong,
				img: redImg,
			},
			{
				remark: true,
				word: 'vuks',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				remark: true,
				word: 'fålk',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
			{
				remark: true,
				word: 'flynt',
				comment: '',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
];
export const assignment5 = [
	{
		id: 5,
		mainImg: puppyImg,
		choices: [
			{
				word: 'modil',
				comment: '',
				sound: carWrong,
				img: redImg,
			},
			{
				word: 'vuks',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'fålk',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'flynt',
				comment: '',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
];

export const assignments = [
	{
		id: 2,
		mainImg: puppyImg,
		choices: [
			{
				word: 'modil',
				comment: '',
				sound: carWrong,
				img: redImg,
			},
			{
				word: 'vuks',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'fålk',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'flynt',
				comment: '',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
	{
		id: 3,
		mainImg: cowImg,
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: yellowImg,
			},
			{
				word: 'peano',
				comment:
					'Peano er feil, fordi vi sier ikke peano – men piano. Ordet peano finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: skyImg,
			},
			{
				word: 'knain',
				comment:
					'Knain er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: blueImg,
			},
		],
	},
	{
		id: 4,
		mainImg: pencilImg,
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
				img: purpleImg,
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: yellowImg,
			},
			{
				word: 'peano',
				comment:
					'Peano er feil, fordi vi sier ikke peano – men piano. Ordet peano finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: skyImg,
			},

			{
				word: 'brillter',
				comment:
					'Brillter er feil, fordi vi sier ikke brillter – men briller. Ordet brillter finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: redImg,
			},
		],
	},
	{
		id: 5,
		mainImg: bulbImg,
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: purpleImg,
			},

			{
				word: 'knain',
				comment:
					'Knain er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'brillter',
				comment:
					'Brillter er feil, fordi vi sier ikke brillter – men briller. Ordet brillter finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
	{
		id: 6,
		mainImg: mittenImg,
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: yellowImg,
			},

			{
				word: 'knain',
				comment:
					'Knain er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'brillter',
				comment:
					'Brillter er feil, fordi vi sier ikke brillter – men briller. Ordet brillter finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: redImg,
			},
		],
	},
];

/* eslint-disable no-console */
import React from 'react';
import queryString from 'query-string';

import './styles.scss';
import Logo from 'assets/logo-white.png';
import {IS_NORMING_VERSION} from 'constants/index';
import createTest from 'api/createTest';

const parsedSearch = queryString.parse(window.location.search);

const error = parsedSearch.error;
const flag = 'JJiisTezRREXXDSSERllliOR321Frrsaas';

class LandingPageNarvik extends React.Component {
	constructor() {
		super();

		this.state = {
			testKey: '',
		};
	}

	generateTestKeyonFly()
	{
		const type = 'child-screening';
		createTest(type).then((res) => 
		{
			if (res.length) {
			const result = res[0];
			this.setState({testKey:result.keyString});
			window.location.href = `/signon?key=${result.keyString}`;
			}
		})
		.catch((e)=>
		{
			console.log('error',e);
		});
	}
	render() {
		

		return (
			<div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="Literate Logo"
						/>
					</h1>

					<div className="right">
						<a className="lit-link" href="http://admin.literate.no">
							Administrator
						</a>
					</div>
				</header>
				{IS_NORMING_VERSION && (
					<>
						<h1 className="lit-landing-page-title">Screeningtest Barn (Dysmate C)</h1>
						<p className="lit-landing-page-subtitle">
							NORMERINGSVERSJON
						</p>
					</>
				)}
				{!IS_NORMING_VERSION && (
					<>
						<h1 className="lit-page-title">Screeningtest Barn (Dysmate C)</h1>
					</>
				)}
				<p className="lit-page-description">
					Trykk på start knappen for å logge på med feide
				</p>
				<div className="lit-wrapper--article">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							this.generateTestKeyonFly();
							
						}}
					>
						<div className="lit-input inline">
							<div className="lit-page-description">
							<button className="lit-btn bg-secondary">
								Start
							</button>
							</div>
						</div>
						{Boolean(error) && (
							<span>En gyldig kommune kreves for å logge inn</span>
						)}
					</form>
				</div>
			</div>
		);
	}
}

export default LandingPageNarvik;

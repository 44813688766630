import React from 'react';
import {connect} from 'react-redux';
import strings from 'utils/strings';

import AudioPlayer from 'components/AudioPlayer';

import './styles.scss';

import audioNo from 'assets/sounds/no/2 1 ordkjedetekst.mp3';
import audioNn from 'assets/sounds/nn/2 1 ordkjedetekst.m4a';

const audioObj = {
	no: audioNo,
	nn: audioNn,
};

class Intro extends React.Component {
	constructor(props) {
		super(props);
		this.audio = audioObj[strings.getLanguage()];
	}
	render() {
		const {onNextStep} = this.props;

		return (
			<div className="lit-intro">
				<p>
					<AudioPlayer autoplay inline src={this.audio} />
					{strings.ordkjedetestInstructions}
				</p>
				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.showExamples}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);

import React from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';
import strings from '../../utils/strings';
import './styles.scss';
import Logo from 'assets/logo-white.png';
import getTestKeyInfo from 'api/getTestKeyInfo';
import {initAuthAction} from 'actions/index';

import {authCallback} from 'utils/bankIdLogin';

import {FEIDE, IS_NORMING_VERSION} from 'constants/index';

import loginNoAuth from 'api/loginNoAuth';
import CustomLogin from '../CustomLogin';

const parsedSearch = queryString.parse(window.location.search);
const search = parsedSearch;
const params = new URLSearchParams(search);

const testKey = parsedSearch.key;
const id = params.get('id');

const authSuccess = parsedSearch.authSuccess;
function IsValidJSONString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
/* const flag = JJiisTezRREXXDSSERllliOR321Frrsaas */
class Login extends React.Component {
	constructor() {
		super();

		this.state = {
			userPin:null,
			isLoginVisible: true,
			passwordLogin: false,
			email: null,
			pinCode: null,
			childSelfStarting: null,
			showCoockieDetectionFrame: false,
			showAltLogin:
				/* id === 'JJiisTezRREXXDSSERllliOR321Frrsaas' || */
				id === 'child-exp-oms-test' ? true : false,
			altError: null,
			isFeide:false,
			instructions:null,
			requiresVerification:false,
			initialPin:null,
			dispatchAddress:null,
			testStatus:null,
		};
	}

	componentDidMount() {
		window.localStorage.removeItem('authtoken-screening');

		if (!testKey) {
			window.location.href = `/?error=true`;
			return;
		}

		// eslint-disable-next-line vars-on-top
		var receiveMessage = function (evt) {
			if (evt.data === 'MM:3PCunsupported') {
				document.getElementById('lit-site-warning').innerHTML =
					strings.warning2;
				document.getElementById('lit-site-warning_text').innerHTML =
					strings.warning2text;
				document.getElementById(
					'lit-site-settings-instructions'
				).innerHTML = strings.linkForInstructions;
				document.getElementById('lit-site-warning_text_end').innerHTML =
					strings.warning_text_end;
			} else if (evt.data === 'MM:3PCsupported') {
				document.getElementById('result').innerHTML = '';
			}
		};
		window.addEventListener('message', receiveMessage, false);

		getTestKeyInfo(testKey).then((response) => {
			const email = response.email;
			const pinCode = response.initialPin;
			const childSelfStarting = response.childSelfStarting;
			const requiresVerification = response.requiresVerification;
			const initialPin = response.initialPin;
			const dispatchAddress = response.dispatchAddress;
			let instructionsText = response.status!=='isCompleted' && response.requiresVerification ?<span>Denne testen er eldre enn en måned. For å sikre ditt personvern må vi bekrefte at det er riktig bruker starter testen. Vennligst bruk pinkoden som vi har sendt til <span style={{color:'white'}}><i><u>{response.dispatchAddress}</u></i></span> for å starte testen.</span>:null;
			if (response.status==='isUsed') {
				instructionsText =<span>Denne testen er startet før, men ikke fullført. For å sikre ditt personvern må vi bekrefte at det er riktig bruker starter testen. Vennligst bruk pinkoden som vi har sendt til <span style={{color:'white'}}><i><u>{response.dispatchAddress}</u></i></span> for å starte testen.</span>;
			}
			const errorToThrow = response.status==='isCompleted' ?'Denne testen er allerede fulført':null;

			this.setState({
				email,
				pinCode,
				childSelfStarting,
				requiresVerification:false,
				passwordLogin:instructionsText?true:false,
				initialPin,
				dispatchAddress,
				altError:errorToThrow,
				instructions:instructionsText,
			});
		}).catch((response) => {
			response.json().then((error) => {
				if (error.error === 'pincode instead') {
					this.setState({
						altError: 'Det ser ut til at du bruker pinkode i stedet for testnøkkel for å starte testen.',
					});
				}
				else{
				this.setState({
						altError: 'Testnøkkelen er ikke gyldig eller allerede brukt.',
					});
				}
			});

		});

		if (authSuccess) {
			let data = atob(
				decodeURIComponent(
					window.location.search.substr(
						window.location.search.indexOf('=') + 1
					)
				)
			);

			if (data && IsValidJSONString(data)) {
				// eslint-disable-next-line consistent-return
				return new Promise((resolve, reject) => {
					data = JSON.parse(data);

					if (data.authError) {
						reject({message: 'There was an error logging you in'});
						return;
					}

					if (
						data &&
						data.action === 'auth' &&
						data.result === 'OK'
					) {
						localStorage.setItem('authtoken-screening', data.authToken);

						window.location.href = `/test`;
						resolve();
						return;
					}
					reject({message: `Kunne ikke logge inn!`});
				});
			} 
		}
	}
	reAffirmTestKeyInfo(testKey) {
		getTestKeyInfo(testKey).then((response) => {
			const email = response.email;
			const pinCode = response.pinCode || this.state.initialPin;
			const childSelfStarting = response.childSelfStarting;
			this.setState({
				email,
				pinCode,
				childSelfStarting,
				passwordLogin: true,
				showCoockieDetectionFrame: false,
			});
		});
	}

	initializeAltLogin = () => {
		// eslint-disable-next-line no-invalid-this
		this.setState({
			showAltLogin: true,
		});
	};
	onAltLoginFieldChange = (e) => {
		// eslint-disable-next-line no-invalid-this
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	submitAltLogin = (e) => {
		e.preventDefault();
		const {firstName, lastName} = this.state;
		const data = {testKey, firstName, lastName};

		loginNoAuth(data)
			.then((results) => {
				authCallback({data: JSON.stringify(results)});
			})
			.catch(() => {
				this.setState({
					altError: 'Ikke gyldig testnøkkel',
				});
			});
	};

	// eslint-disable-next-line space-before-blocks
	comparePinCodes(){
		if (this.state.initialPin.toLowerCase() === this.state.userPin.toLowerCase()) {
			this.setState({
				requiresVerification:false,
				instructions:null
			});

		}
		else {
			this.setState({
				altError:'Feil pinkode. Testen kan ikke startes'
			});
		}
	}

	render() {
		const {isLoginVisible, showAltLogin, altError, isFeide} = this.state;

		if (!isLoginVisible) {
			return null;
		}

		const {error, iframeUrl, initAuth, isInit} = this.props;

		const showIframe = isInit && iframeUrl && !showAltLogin;
		const showError = isInit && error && !showAltLogin;
		const showLoginButtons = !isInit && !showAltLogin;
		if (showIframe && isFeide) {window.location.href = `${iframeUrl}`;}
		return (
			<div className="Login">
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate logo"
						/>
					</h1>
				</header>

				<div className="lit-wrapper--article text-center">
					<h2>Innlogging</h2>
					{!IS_NORMING_VERSION && (
						<p>
							<span>
								Velg om du ønsker å logge inn med Feide eller
								pinkode.
							</span>
							<br></br>
							<span>Spør gjerne en voksen om hjelp.</span>
							<br></br>
						</p>
					)}
					{IS_NORMING_VERSION && (
						<p>
							Siden dette er en normeringstest er det ikke påkrevd
							at du logger inn med en autorisert påloggingsmetode.
							Dersom du har en BankID eller feide aktivert for
							Literate sine tester bør du bruke disse. Om ikke,
							velg "ingen innlogging"
						</p>
					)}
					{!this.state.requiresVerification && showLoginButtons && !this.state.passwordLogin && !altError &&(
						<div className="bank-id-type-selector-wrapper">
							<button
								className="lit-btn lit-btn--small bg-secondary"
								onClick={() => {
									this.setState({
										showCoockieDetectionFrame: true,
										isFeide:true,
									});
									initAuth(FEIDE, testKey, false);
								}}
							>
								Feide
							</button>
							<button
								className="lit-btn lit-btn--small bg-secondary"
								onClick={() => {
									this.reAffirmTestKeyInfo(testKey);
								}}
							>
								Pinkode
							</button>
							{IS_NORMING_VERSION && (
								<>
									<br />
									<br />
									<button
										className="lit-btn lit-btn--small bg-secondary"
										onClick={() => {
											this.initializeAltLogin();
										}}
									>
										Ingen innlogging
									</button>
								</>
							)}
						</div>
					)}
					

						{altError && (
						<div>
						<br />
						<span className="error" style={{color:'yellow', fontSize:'18px',overflowWrap:'breakWord'}}>
							<span style={{fontWeight:'bold', color:'#ff6600', textShadow:'black 1px 1px 1px ',fontSize:'20px', marginRight:'3px', overflowWrap:'break-word', }}><i>{`Error: `}</i></span>
							<span>{'	 '}</span>
						{altError}
						</span>
						</div>
								)}
						{!altError && this.state.instructions && (
							<div>
							<br />
							<span className="error" style={{color:'yellow', fontSize:'18px',overflowWrap:'breakWord'}}>
								<span style={{fontWeight:'bold', color:'#ff6600', textShadow:'black 1px 1px 1px ',fontSize:'20px', marginRight:'3px', overflowWrap:'break-word', }}><i>{` `}</i></span>
								<span>{'	 '}</span>
							{this.state.instructions}
							</span>

							</div>
						)}
					

					<p className="lit-thirdPCsupport" id="result" />
					<div className="lit-thrid-party-settings">
						<p
							className="candidate-start-fail__instructions"
							style={{
								color: 'white',
								margin: 'auto',
								textAlign: 'center',
								fontSize: '18px',
							}}
						>
							<span style={{color: 'Red'}}>
								<b>
									<span id="lit-site-warning" />{' '}
								</b>
							</span>
							<em>
								<span id="lit-site-warning_text" />
							</em>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.dysmate.no/advarsel-2/"
							>
								<u style={{color: 'Lime'}}>
									<span id="lit-site-settings-instructions" />
								</u>
							</a>
							<em>
								<span id="lit-site-warning_text_end" />
							</em>
						</p>
					</div>
					{showIframe && !isFeide && (
						<div className="bank-id-iframe-wrapper">
							<iframe
								title="login-frame"
								frameBorder="0"
								id="authFrame"
								scrolling="no"
								src={iframeUrl}
								style={{
									height: '600px',
									width: '100%',
								}}
							/>
						</div>
					)}
					{this.state.passwordLogin && (
						<div className="lit-customlogin-sub-wrapper">
							<CustomLogin
								email={this.state.email}
								pinCode={this.state.pinCode}
								childSelfStarting={this.state.childSelfStarting}
							/>
						</div>
					)}

					{showError && <div>{error}</div>}
					{showAltLogin && (
						<div className="alternate-login">
							<p>
								For å logge inn uten BankID eller Feide trenger
								vi å vite hva du heter
							</p>
							<form onSubmit={this.submitAltLogin}>
								<label>
									Fornavn
									<input
										type="text"
										name="firstName"
										id="firstName"
										onChange={this.onAltLoginFieldChange}
									/>
								</label>
								<label>
									Etternavn
									<input
										type="text"
										name="lastName"
										id="lastName"
										onChange={this.onAltLoginFieldChange}
									/>
								</label>
								{altError && (
									<p className="error">{altError}</p>
								)}
								<button
									className="lit-btn lit-btn--small"
									type="submit"
								>
									Logg inn
								</button>
							</form>
						</div>
					)}

					{this.state.showCoockieDetectionFrame && (
						<div>
							<iframe
								src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
								style={{display: 'none'}}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import voice1No from 'assets/sounds/no/bytt-no-1.mp3';
import voice2No from 'assets/sounds/no/bytt-no-2.mp3';
import voice3No from 'assets/sounds/no/bytt-no-3.mp3';
import voice4No from 'assets/sounds/no/bytt-no-4.mp3';
import voice5No from 'assets/sounds/no/bytt-no-5.mp3';
import voice6No from 'assets/sounds/no/bytt-no-6.mp3';
import voice7No from 'assets/sounds/no/bytt-no-7.mp3';
import voice8No from 'assets/sounds/no/bytt-no-8.mp3';
import voice9No from 'assets/sounds/no/bytt-no-9.mp3';
import voice10No from 'assets/sounds/no/bytt-no-10.mp3';
import voice11No from 'assets/sounds/no/bytt-no-11.mp3';
import voice12No from 'assets/sounds/no/bytt-no-12.mp3';
import voice13No from 'assets/sounds/no/bytt-no-13.mp3';
import voice14No from 'assets/sounds/no/bytt-no-14.mp3';
import voice15No from 'assets/sounds/no/bytt-no-15.mp3';
import voice16No from 'assets/sounds/no/bytt-no-16.mp3';
import voice17No from 'assets/sounds/no/bytt-no-17.mp3';
import voice18No from 'assets/sounds/no/bytt-no-18.mp3';
import voice19No from 'assets/sounds/no/bytt-no-19.mp3';
import voice20No from 'assets/sounds/no/bytt-no-20.mp3';
import voice21No from 'assets/sounds/no/bytt-no-21.mp3';

import monkey from '../assets/Ape.PNG';
import banana from '../assets/Banan.PNG';
import bee from '../assets/Bie.PNG';
import children from '../assets/barn.png';
import thread from '../assets/garn.png';
import car from '../assets/Bil.PNG';
import pencil from '../assets/Blyant.PNG';
import table from '../assets/Bord.PNG';

import door from '../assets/Dør.PNG';
import six from '../assets/Seks.PNG';
import sock from '../assets/Sokk.PNG';
import shoe from '../assets/Sko.PNG';
import mouse from '../assets/Mus.PNG';
import sky from '../assets/sky.png';
import onion from '../assets/Løk.PNG';
import roof from '../assets/Tak.PNG';
import sami from '../assets/Same.PNG';
import lock from '../assets/Lås.PNG';

import palmTree from '../assets/Palme.PNG';
import running from '../assets/Løpe.PNG';

import five from '../assets/Fem.PNG';
import four from '../assets/Fire.PNG';
import garage from '../assets/Garasje.PNG';
import glass from '../assets/Glass.PNG';
import pudding from '../assets/Grøt.PNG';
import cabin from '../assets/Hytte.PNG';
import comb from '../assets/Kam.PNG';
import treasure from '../assets/Kiste.PNG';
import dress from '../assets/Kjole.PNG';
import chicken from '../assets/Kylling.PNG';
import vest from '../assets/Vest.PNG';
import purse from '../assets/Veske.PNG';
import sun from '../assets/Sol.PNG';
import mushroom from '../assets/Sopp.PNG';
import beanie from '../assets/Lue.PNG';
import seven from '../assets/Syv.PNG';
import wet from '../assets/Våt.PNG';
import needle from '../assets/Sy.PNG';
import ax from '../assets/Øks.PNG';
import eight from '../assets/Åtte.PNG';

import book from '../assets/Bok.PNG';
import boat from '../assets/Båt.PNG';
import blue from '../assets/blue.png';
import bus from '../assets/Buss.PNG';
import camel from '../assets/Kamel.PNG';
import cinamon from '../assets/Kanel.PNG';
import house from '../assets/Hus.PNG';
import puppy from '../assets/Hund.PNG';
import horse from '../assets/Hest.PNG';
import cheek from '../assets/Kinn.PNG';
import cup from '../assets/Kopp.PNG';
import lice from '../assets/Lus.PNG';
import witch from '../assets/Heks.PNG';
import hand from '../assets/Hånd.PNG';
import bag from '../assets/Sekk.PNG';
import ski from '../assets/Ski.PNG';
import number from '../assets/Tall.PNG';
import saw from '../assets/Sage.PNG';
import slippery from '../assets/Glatt.PNG';
import slide from '../assets/Ake.PNG';
import skin from '../assets/Skinn.PNG';
import iphone from '../assets/Iphone.PNG';
import ear from '../assets/Øre.PNG';
import thumb from '../assets/Tommel.PNG';
import pencilCase from '../assets/Pennal.PNG';

import green from '../assets/green.png';
import yellow from '../assets/yellow.png';
import red from '../assets/red.png';
import purple from '../assets/purple.png';

import cow from '../assets/Ku.PNG';
import bulb from '../assets/Lys.PNG';
import mitten from '../assets/Votter.PNG';

import starterTesten from 'assets/sounds/no/3 13 Tulleord Starter testen.mp3';

export const starterTestenSound = starterTesten;

export const bytteLydenExample = {};

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'lys',
		},
		{
			word: 'lese',
		},
		{
			word: 'katt',
		},
		{
			isCorrect: true,
			word: 'kam',
		},
	],
};
export const assignment1 = [
	{
		id: 1,
		mainImg: horse,
		text: 'Bytt /h/ med /v/ ',
		mainSound: voice1No,
		choices: [
			{
				word: 'vest',

				img: vest,
			},
			{
				word: 'ku',

				img: cow,
			},
			{
				word: 'veske',

				img: purse,
			},
			{
				word: 'hånd',

				img: hand,
			},
		],
	},
];
export const assignment2 = [
	{
		id: 2,
		mainImg: cup,
		text: 'Bytt /k/ med /s/ ',
		mainSound: voice2No,
		choices: [
			{
				word: 'sol',

				img: sun,
			},
			{
				word: 'sopp',

				img: mushroom,
			},
			{
				word: 'glass',

				img: glass,
			},
			{
				word: 'bana',

				img: banana,
			},
		],
	},
];
export const assignment3 = [
	{
		id: 3,
		mainImg: children,
		text: 'Bytt /b/ med /g/ ',
		mainSound: voice3No,
		choices: [
			{
				word: 'lue',

				img: beanie,
			},
			{
				word: 'blyant',

				img: pencil,
			},
			{
				word: 'garn',

				img: thread,
			},
			{
				word: 'sekk',

				img: bag,
			},
		],
	},
];
export const assignment4 = [
	{
		id: 4,
		mainImg: witch,
		text: ' Bytt /h/ med /s/',
		mainSound: voice4No,
		choices: [
			{
				word: 'fire',

				img: four,
			},
			{
				word: 'fem',

				img: five,
			},
			{
				word: 'seks',

				img: six,
			},
			{
				word: 'siv',

				img: seven,
			},
		],
	},
];
export const assignment5 = [
	{
		id: 5,
		mainImg: boat,
		text: 'Bytt /b/ med /v/ ',
		mainSound: voice5No,
		choices: [
			{
				word: 'ku',

				img: cow,
			},
			{
				word: 'votter',

				img: mitten,
			},
			{
				word: 'bil',

				img: car,
			},
			{
				word: 'våt',

				img: wet,
			},
		],
	},
];
export const assignment6 = [
	{
		id: 6,
		mainImg: house,
		text: 'Bytt /h/ med /m/',
		mainSound: voice6No,
		choices: [
			{
				word: 'sy',

				img: needle,
			},
			{
				word: 'mus',

				img: mouse,
			},
			{
				word: 'garsje',

				img: garage,
			},
			{
				word: 'kylling',

				img: chicken,
			},
		],
	},
];
export const assignment7 = [
	{
		id: 7,
		mainImg: cheek,
		text: 'Bytt /kj/ med /skj/',
		mainSound: voice7No,
		choices: [
			{
				word: 'skinn',

				img: skin,
			},
			{
				word: 'kjole',

				img: dress,
			},
			{
				word: 'kiste',

				img: treasure,
			},
			{
				word: 'iPhone',

				img: iphone,
			},
		],
	},
];
export const assignment8 = [
	{
		id: 8,
		mainImg: hand,
		text: ' Bytt /å/ med /u/',
		mainSound: voice8No,
		choices: [
			{
				word: 'øre',

				img: ear,
			},
			{
				word: 'tommel',

				img: thumb,
			},
			{
				word: 'hund',

				img: puppy,
			},
			{
				word: 'hus',

				img: house,
			},
		],
	},
];
export const assignment9 = [
	{
		id: 9,
		mainImg: lice,
		text: 'Bytt /u/ med /y/',
		mainSound: voice9No,
		choices: [
			{
				word: 'hytte',

				img: cabin,
			},
			{
				word: 'lue',

				img: beanie,
			},
			{
				word: 'kam',

				img: comb,
			},
			{
				word: 'lys',

				img: bulb,
			},
		],
	},
];
export const assignment10 = [
	{
		id: 10,
		mainImg: puppy,
		text: ' Bytt /u/ med /å/ ',
		mainSound: voice10No,
		choices: [
			{
				word: 'veske',

				img: purse,
			},
			{
				word: 'hånd',

				img: hand,
			},
			{
				word: 'votter',

				img: mitten,
			},
			{
				word: 'kylling',

				img: chicken,
			},
		],
	},
];
export const assignment11 = [
	{
		id: 11,
		mainImg: bag,
		text: 'Bytt /e/ med /å/',
		mainSound: voice11No,
		choices: [
			{
				word: 'sopp',

				img: mushroom,
			},
			{
				word: 'sko',

				img: shoe,
			},
			{
				word: 'pennal',

				img: pencilCase,
			},
			{
				word: 'sokk',

				img: sock,
			},
		],
	},
];
export const assignment12 = [
	{
		id: 12,
		mainImg: cinamon,
		text: ' Bytt /n/ med /m/ ',
		mainSound: voice12No,
		choices: [
			{
				word: 'kamel',

				img: camel,
			},
			{
				word: 'grøt',

				img: pudding,
			},
			{
				word: 'bil',

				img: car,
			},
			{
				word: 'palme',

				img: palmTree,
			},
		],
	},
];
export const assignment13 = [
	{
		id: 13,
		mainImg: saw,
		text: ' Bytt /g/ med /m/',
		mainSound: voice13No,
		choices: [
			{
				word: 'tommel',

				img: thumb,
			},
			{
				word: 'øks',

				img: ax,
			},
			{
				word: 'same',

				img: sami,
			},
			{
				word: 'lås',

				img: lock,
			},
		],
	},
];
export const assignment14 = [
	{
		id: 14,
		mainImg: slide,
		text: ' Bytt /k/ med /p/',
		mainSound: voice14No,
		choices: [
			{
				word: 'kopp',

				img: cup,
			},
			{
				word: 'ape',

				img: monkey,
			},
			{
				word: 'ski',

				img: ski,
			},
			{
				word: 'løpe',

				img: running,
			},
		],
	},
];
export const assignment15 = [
	{
		id: 15,
		mainImg: slippery,
		text: 'Bytt /t/ med /s/',
		mainSound: voice15No,
		choices: [
			{
				word: 'seks',

				img: six,
			},
			{
				word: 'våt',

				img: wet,
			},
			{
				word: 'lue',

				img: beanie,
			},
			{
				word: 'glass',

				img: glass,
			},
		],
	},
];
export const assignment16 = [
	{
		id: 16,
		mainImg: number,
		text: 'Bytt /l/ med /k/ ',
		mainSound: voice16No,
		choices: [
			{
				word: 'tak',

				img: roof,
			},
			{
				word: 'bylant',

				img: pencil,
			},
			{
				word: 'løk',

				img: onion,
			},
			{
				word: 'sol',

				img: sun,
			},
		],
	},
];
export const assignment17 = [
	{
		id: 17,
		mainImg: lice,
		text: 'Bytt /s/ med /e/',
		mainSound: voice17No,
		choices: [
			{
				word: 'øre',

				img: ear,
			},
			{
				word: 'mus',

				img: mouse,
			},
			{
				word: 'lue',

				img: beanie,
			},
			{
				word: 'veske',

				img: purse,
			},
		],
	},
];
export const assignment18 = [
	{
		id: 18,
		mainImg: ski,
		text: ' Bytt /i/ med /y/',
		mainSound: voice18No,
		choices: [
			{
				word: 'sol',

				img: sun,
			},
			{
				word: 'sky',

				img: sky,
			},
			{
				word: 'sko',

				img: shoe,
			},
			{
				word: 'lys',

				img: bulb,
			},
		],
	},
];
export const assignment19 = [
	{
		id: 19,
		mainImg: book,
		text: 'Bytt /k/ med /r/',
		mainSound: voice19No,
		choices: [
			{
				word: 'lys',

				img: bulb,
			},
			{
				word: 'dør',

				img: door,
			},
			{
				word: 'pennal',

				img: pencilCase,
			},
			{
				word: 'bord',

				img: table,
			},
		],
	},
];
export const assignment20 = [
	{
		id: 20,
		mainImg: puppy,
		text: 'Bytt /nd/ med /s/',
		mainSound: voice20No,
		choices: [
			{
				word: 'hus',

				img: house,
			},
			{
				word: 'kamel',

				img: camel,
			},
			{
				word: 'mus',

				img: mouse,
			},
			{
				word: 'hest',

				img: horse,
			},
		],
	},
];
export const assignment21 = [
	{
		id: 21,
		mainImg: car,
		text: ' Bytt /l/ med /e/',
		mainSound: voice21No,
		choices: [
			{
				word: 'buss',

				img: bus,
			},
			{
				word: 'vest',

				img: vest,
			},
			{
				word: 'bie',

				img: bee,
			},
			{
				word: 'Åtte',

				img: eight,
			},
		],
	},
];
export const assignment22 = [
	{
		id: 22,
		mainImg: puppy,
		text: '',
		mainSound: voice21No,
		choices: [
			{
				word: 'modil',

				img: red,
			},
			{
				word: 'vuks',

				img: green,
			},
			{
				word: 'fålk',

				img: blue,
			},
			{
				word: 'flynt',

				img: yellow,
			},
		],
	},
];

export const assignments = [
	{
		id: 2,
		mainImg: puppy,
		choices: [
			{
				word: 'modil',

				img: red,
			},
			{
				word: 'vuks',

				img: green,
			},
			{
				word: 'fålk',

				img: blue,
			},
			{
				word: 'flynt',

				img: yellow,
			},
		],
	},
	{
		id: 3,
		mainImg: cow,
		choices: [
			{
				word: 'vorr',

				img: green,
			},
			{
				word: 'blastell',

				img: yellow,
			},
			{
				word: 'peano',

				img: sky,
			},
			{
				word: 'knain',

				img: blue,
			},
		],
	},
	{
		id: 4,
		mainImg: pencil,
		choices: [
			{
				word: 'vorr',

				img: purple,
			},
			{
				word: 'blastell',

				img: yellow,
			},
			{
				word: 'peano',

				img: sky,
			},

			{
				word: 'brillter',

				img: red,
			},
		],
	},
	{
		id: 5,
		mainImg: bulb,
		choices: [
			{
				word: 'vorr',

				img: green,
			},
			{
				word: 'blastell',

				img: purple,
			},

			{
				word: 'knain',

				img: blue,
			},
			{
				word: 'brillter',

				img: yellow,
			},
		],
	},
	{
		id: 6,
		mainImg: mitten,
		choices: [
			{
				word: 'vorr',

				img: green,
			},
			{
				word: 'blastell',

				img: yellow,
			},

			{
				word: 'knain',

				img: blue,
			},
			{
				word: 'brillter',

				img: red,
			},
		],
	},
];

import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import strings from 'utils/strings';

import 'react-datepicker/dist/react-datepicker.css';

import './styles.scss';

import submitTestUserInfo from 'api/submitTestUserInfo';
import markKeyAsUsed from 'api/markKeyAsUsed';

import {goToNextStep} from 'actions/index';
import {setTestCandidateGrade} from 'actions/index';

import {updateObject} from 'utils/reducerUtils';
import {SassColor} from 'sass';

//import {TEST_USER_INFO_LABELS} from 'constants/index';

moment.locale('nb');

const TEST_USER_INFO_LABELS = {
	gender: strings.gender,
	juniorHigh: strings.juniorHigh,
	highSchool: strings.highSchool,
	bachelor: strings.bachelor,
	master: strings.master,
	phd: strings.phd,
	other: strings.other,
	youthProgram: strings.youthProgram,
	accompanyingMeasures: strings.accompanyingMeasures,
	workPreparatoryTraining: strings.workPreparatoryTraining,
	norwegian: strings.norwegian,
	sapmi: strings.sapmi,
	russian: strings.russian,
	english: strings.english,
	arabic: strings.arabic,
	polish: strings.polish,
	kurdish: strings.kurdish,
	spanish: strings.spanish,
	tamil: strings.tamil,
	thai: strings.thai,
	turkish: strings.turkish,
	yes: strings.yes,
	no: strings.no,
	doesNotKnow: strings.doesNotKnow,
	youthGrade2: strings.youthGrade2,
	youthGrade3: strings.youthGrade3,
	youthGrade4: strings.youthGrade4,
	youthGrade5: strings.youthGrade5,
	youthGrade6: strings.youthGrade6,
	youthGrade7: strings.youthGrade7,
	youthGrade8: strings.youthGrade8,
	youthGrade9: strings.youthGrade9,
	youthGrade10: strings.youthGrade10,
};


const childGrades = [2, 3, 4, 5, 6, 7];

const GENDER = 'gender';
const EDUCATION = 'education';
const YOUTH_GRADE = 'youthGrade';

const now = moment();
let tabIndexVal = 0;

class Introduksjon extends React.Component {
	constructor() {
		super();
		strings.setLanguage(`${strings.getLanguage()}`);

		this.state = {
			submitEnabled: true,
			hasSelectedLanguage: false,
			isFormValid: false,
			dateIsWrong: false,
			showDateError: false,
			defaultBirthDate: moment().subtract(10, 'year').toDate(),
			birthDate: moment().subtract(10, 'year').toDate(),
			candidateGrade: null,
			testUser: {
				firstName:'',
				lastName:'',
				birthDate: '',
				gender: '',
				email: '',
			
				age: {
					years: 0,
					months: 0,
				},
				education: {
					youthGrade: 0,
				},
				
			},
		};
		this.onSelectLanguage = this.onSelectLanguage.bind(this);
		this.handleUpdateTestCandidateEvent =
			this.handleUpdateTestCandidateEvent.bind(this);
	}

	updateTestUser(categoryKey, key, value) {
		const {testUser} = this.state;

		if (categoryKey) {
			const category = updateObject(testUser[categoryKey], {
				[key]: value,
			});

			this.setState({
				testUser: updateObject(testUser, {
					[categoryKey]: category,
				}),
			});
		} else {
			this.setState({
				testUser: updateObject(testUser, {
					[key]: value,
				}),
			});
		}
	}
	updateTestUserBirthDate(key, value, keyBirth, valBirth) {
		const {testUser} = this.state;
		this.setState({
			testUser: updateObject(testUser, {
				[key]: value,
				[keyBirth]: valBirth,
			}),
		});
	}

	onSelectLanguage(lang) {
		strings.setLanguage(lang);
		this.setState({
			hasSelectedLanguage: true,
		});

		if (this.props.candidateGrade) {
			markKeyAsUsed().then(() => {
				this.props.onGoToNextStep();
			});
		}
	}
	handleUpdateTestCandidateEvent = (event) => {
		const target = event.target;

		const key = target.name;
		const value = target.value;
		this.handleUpdate(key, value);
	};

	handleUpdate = (key, value) => {
		const {testUser} = this.state;
		this.setState({
			testUser: updateObject(testUser, {
				[key]: value,
			}),
		});
	};
	render() {
		const {onGoToNextStep, onSetTestCandidateGrade} = this.props;
		const genders = [
			{
				label: strings.female,
				value: 'f',
			},
			{
				label: strings.male,
				value: 'm',
			},
		];
		const {
			isFormValid,
			testUser,
			hasSelectedLanguage,
			defaultBirthDate
		} = this.state;

		const {
			education,
			gender,
		} = testUser;

		const yearsToSubtract = 5;
		let formRef;

		return !hasSelectedLanguage ? (
			<div className="lit-test-intro">
				<h1>{`Velg/vel språk`}</h1>
				<button
					className="lit-test-intro-language-button"
					tabIndex={tabIndexVal + 1}
					onClick={() => this.onSelectLanguage('no')}
				>
					{`Norsk Bokmål`}
				</button>
				<button
					className="lit-test-intro-language-button"
					tabIndex={tabIndexVal + 1}
					onClick={() => this.onSelectLanguage('nn')}
				>
					{`Norsk Nynorsk`}
				</button>
			</div>
		) : (
			<div className="lit-test-intro">
				<h1 className="lit-page-title">{strings.welcomeToTest}</h1>
				<div className="lit-intro">
					<p>{strings.introInstructions}</p>
					<form
						className="pre-test-form"
						onChange={() => {
							const formValidity = formRef.checkValidity();

							if (isFormValid !== formValidity) {
								this.setState({
									isFormValid: formValidity,
								});
							}
						}}
						onSubmit={(event) => {
							event.preventDefault();
							if (!this.state.submitEnabled) return;
							this.setState({
								submitEnabled: false,
							});
							const {age} = testUser;

							if (age.years < 3 || age.years > 90) {
								this.setState({
									dateIsWrong: true,
									showDateError: true,
								});
								this.setState({
									submitEnabled: true,
								});
							} else {
								submitTestUserInfo(testUser)
									.then(onGoToNextStep)
									.catch((err) => {
										console.log(err);
										this.setState({
											submitEnabled: true,
										});
									});
							}
						}}
						ref={(ref) => {
							formRef = ref;
						}}
					>
						<h2>{strings.general}</h2>
						<div className="lit-input inline">
						<label className="lit-input__label wider">
								{strings.firstName}
						</label>
								<input
									className="lit-input__field"
									id="test-candidate-modal-firstName"
									name="firstName"
									onChange={
										this.handleUpdateTestCandidateEvent
									}
									placeholder="Nordmann"
									type="text"
									value={this.state.testUser.firstName}
									required
								/>
							</div>
							<div className="lit-input inline">
							<label className="lit-input__label wider">
								{strings.lastName}
						</label>
								<input
									className="lit-input__field"
									id="test-candidate-modal-lastName"
									name="lastName"
									onChange={
										this.handleUpdateTestCandidateEvent
									}
									placeholder="Nordmann"
									type="text"
									value={this.state.testUser.lastName}
									required
								/>
							</div>
						<div className="lit-input inline">
							<label className="lit-input__label wider">
								{strings.gender}
							</label>
							<div className="lit-input__field lit-radios">
								{genders.map((genderOption, index) => {
									const {
										label: genderLabel,
										value,
									} = genderOption;
									tabIndexVal = tabIndexVal + 3 + index;
									return (
										<span className="lit-radio" key={value}>
											<input
												tabIndex={
													tabIndexVal + 3 + index
												}
												checked={value === gender}
												className="lit-radio__field"
												id={`gender${value}`}
												name="gender"
												onChange={() => {
													this.updateTestUser(
														null,
														GENDER,
														value
													);
												}}
												required
												type="radio"
											/>
											<label
												className="lit-radio__label"
												htmlFor={`gender${value}`}
											>
												{genderLabel}
											</label>
										</span>
									);
								})}
							</div>
						</div>
						<div className="lit-input inline">
							<label
								className="lit-input__label wider"
								htmlFor=""
							>
								{strings.birthDate}
							</label>
							<DatePicker
								className="lit-input__field"
								dropdownMode="select"
								inline
								maxDate={moment()
									.subtract(yearsToSubtract, 'years')
									.toDate()}
								name="age"
								onChange={(value) => {
									const numberOfMonths = 12;
									this.setState(
										{
											defaultBirthDate: value,
										},
										() => {
											const age = {
												years: now.diff(value, 'years'),
												months:
													now.diff(value, 'months') %
													numberOfMonths,
											};
											if (
												age.years > 2 &&
												age.years < 90
											) {
												this.setState({
													dateIsWrong: false,
													showDateError: false,
												});
											} else {
												this.setState({
													dateIsWrong: true,
												});
											}

											this.updateTestUserBirthDate('age', age, 'birthDate',value);
										}
									);
								}}
									required
									placeholderText={strings.clickToSelectDate}
									selected={defaultBirthDate}
									adjustDateOnChange
									showYearDropdown
								/>
						</div>

						<h2>{strings.education}</h2>
						<div className="lit-input">
							<label className="lit-input__label wider">
								{strings.questionYouthGrade}
							</label>
							<div className="lit-input__field lit-radios">
								{childGrades.map((grade, indexVal) => {
									return (
										<span className="lit-radio" key={grade}>
											<input
												checked={
													grade ===
													education[YOUTH_GRADE]
												}
												tabIndex={
													tabIndexVal + 3 + indexVal
												}
												className="lit-radio__field"
												id={`youthGrade${grade}`}
												name="grade"
												onChange={() => {
													this.setState({
														candidateGrade: grade,
													});
													onSetTestCandidateGrade(
														grade
													);
													this.updateTestUser(
														EDUCATION,
														YOUTH_GRADE,
														grade
													);
												}}
												required
												type="radio"
											/>
											<label
												className="lit-radio__label"
												htmlFor={`youthGrade${grade}`}
											>
												{
													TEST_USER_INFO_LABELS[
														'youthGrade' + grade
													]
												}
											</label>
										</span>
									);
								})}
							</div>
						</div>

						<div>
							<label>
								<input
									id="agreedToPrivacyPolicy"
									name="agreedToPrivacyPolicy"
									required
									type="checkbox"
								/>{' '}
								{strings.privacyPolicyLabel}
							</label>
							<br />
							{strings.formatString(
								strings.privacyPolicyText,
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://literate.no/personvernerklaering/"
								>
									{strings.privacyPolicy}
								</a>
							)}
							<br></br>
						</div>

						{this.state.showDateError && (
							<div style={{marginTop: '10px'}}>
								<span
									style={{
										color: 'Yellow',
										fontSize: '15px',
										justifyContent: 'center',
										margin: 'auto',
										fontWeight: 'bold',
									}}
								>
									<i>{`Er du virkelig ${testUser.age.years} år gammel? Kanskje fødselsdato ikke er satt riktig?`}</i>
								</span>
							</div>
						)}

						<button
							className="lit-btn lit-btn--huge bg-primary"
							type="submit"
							tabIndex={tabIndexVal + 10}
						>
							{strings.start}
						</button>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {candidateGrade} = state;
	return {
		candidateGrade,
	};
};

const mapDispatchToProps = {
	onGoToNextStep: goToNextStep,
	onSetTestCandidateGrade: setTestCandidateGrade,
};

export default connect(mapStateToProps, mapDispatchToProps)(Introduksjon);

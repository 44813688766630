import React from 'react';

export const TextGrade2 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`VENNER FOR LIVET`}</h2>
				<p>
					{`Noah har mange venner.
			Abdi har mange venner han også. Ikke alle har venner.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Hvor mange venner har Abdi?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'mange',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'ingen',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'én venn',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'to venner',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				{<h2 className="text-center">{`2 av 8`}</h2>}
				<p>
					{`Pål har ingen venner. Pål er mye alene. Noah går på besøk til Pål i dag.
			Han er snill og flink. Det er ingen grunn til at Pål ikke skal ha venner.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Hvordan vet vi at Pål ikke har noen venner?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Han bor i skogen',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'Han krangler mye',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'Han er mye alene',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'Han er dum',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				{<h2 className="text-center">{`3 av 8`}</h2>}
				<p>
					{`
					Pål kan bli venn med Noah. Noah vil ha nye venner. Noen samler på ting. Noah samler på venner.
			
					Pål har mange spill. Han har også puslespill, brio og dam. `}
				</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Hvordan vet vi at Noah liker å ha mange venner?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'han liker nyheter',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'han samler på ting',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'han liker å spille spill med andre',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'han samler på venner',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				{<h2 className="text-center">{`4 av 8`}</h2>}
				<p>
					{`Noah liker brio bedre enn dam. Dam er ikke så gøy. Dam er det bare voksne som spiller.
			Noah kjenner ingen barn som spiller dam. Det er bedre å leke med brio.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Hvem er det Noah mener det er som spiller dam oftest?',
				choices: [
					{
						letter: 'a',
						identity: 'c',
						choice: 'barn',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'voksne',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'dyr i dammer',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'damer',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>{`Pål er hjemme. Noah spør om han får komme på besøk. Pål blir glad. `}</p>
				<p>{`- Kom inn, sier Pål. - Jeg har fått et nytt spill.`}</p>
				<p>{`- Har du fått et nytt spill? spør Noah. `}</p>
				<p>{`- Jeg har fått et nytt dataspill. `}</p>
				<p>{`- Så gøy! Er det bra? `}</p>
				<p>{`- Ja, sier Pål. Det er kult!`}</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Hvorfor blir Pål glad?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'han kjeder seg',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'han får et nytt spill',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'han skal bade',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'fordi han får besøk',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>
					{`Noah blir med Pål til rommet. Det er så mange spill der, at han ikke vet hvor han skal sitte.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Hvor går de for å spille?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'i kjelleren',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'på stua',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'på rommet',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'på kjøkkenet',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>
					{`Pål setter seg ned og spiller.
			Noah setter seg ned han også.
			Først setter Noah seg på lego, men flytter på seg. Pål spiller godt. Han er veldig flink til å spille bilspill. Noah er flink i sport.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Hvorfor flytter Noah på seg?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'fordi sofaen er vond',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'fordi han setter seg på Lego',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'han ser ikke dataskjermen',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'han har slått seg på foten',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>{`Når de er lei av å spille, må Noah hjem igjen. `}</p>
				<p>{`- Takk for i dag, sier han.`}</p>
				<p>{`- Skal vi møtes igjen i morgen? spør Pål.`}</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Hvordan vet Noah at han har fått en ny venn?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Pål spør om de er venner',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Pål er flink til å spille dataspill',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'Pål spør om de skal møtes igjen i morgen',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Pål gir Noah en klem',
					},
				],
			},
		],
	},
];

export const TextGrade3 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`MIRA ER BORTE!`}</h2>
				<p>{`- Mira er borte!`}</p>
				<p>{`- Hva er det du sier, Lise? Mamma ser trist på henne.`}</p>
				<p>{`- Når så du henne sist? `}</p>
				<p>{`- For bare fem minutter siden, sier Lise.`}</p>
				<p>{`- Noen har latt døren stå åpen igjen!`}</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Når så Lise hunden Mira sist?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'for fem timer siden',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'for fem minutter siden',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'for fem dager siden',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'for femten minutter siden',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>
					{`Lise kikker bort på pappa. Det var han som lot døren stå oppe sist Mira ble borte.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Hvorfor tror du Lise kikket bort på faren?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'faren vet hvor Mira er',
					},
					{
						letter: 'b',
						identity: 'd',
						choice:
							'hun mistenker faren for å ha latt døren stå åpen',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'faren vet mer enn han sier',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'faren har glemt Mira ute',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>{`Huff, sier pappa.`}</p>
				<p>{`- Det var nok meg igjen. Jeg var bare ute og hentet posten.`}</p>
				<p>{`- Du må da lære deg å lukke døren etter deg. Du er da voksen! sier Lise surt.`}</p>
				<p>{`Unnskyld, sier pappa og blir rød i ansiktet. Lise tar på seg jakken og går ut i kulden. Det er ti minus og snø ute.`}</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text:
					'Hvorfor mener Lise at faren burde klare å lukke døren etter seg?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'faren vet mer enn han sier',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'fordi han er voksen',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'faren har glemt Mira ute',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'faren vet hvor Mira er',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>{`- Mira! roper Lise, men ingen svarer. `}</p>
				<p>{`- Mira! roper mamma og pappa.`}</p>
				<p>{`Lise ser etter spor i snøen. Det er nysnø ute, og ingen spor etter Mira.`}</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text:
					'Hva kan grunnen være til at det ikke er spor etter Mira ute?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Mira har gått et annet sted',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'Mira har blitt stjålet av noen',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Mira har stukket av',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'det er nysnø ute',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>{`- Vi deler oss opp, sier mamma og pappa.`}</p>
				<p>{`- Lise, du kan gå rundt i hagen og sjekke om Mira er der, så går vi en runde i nabolaget.`}</p>
				<p>{`- OK! sier Lise, og går inn i garasjen og sjekker.`}</p>
				<p>{`Tomt. Ikke noen spor etter Mira. Lise sjekker i hundehuset også.
				Tomt der også. Hun går en runde rundt huset, men Mira er ikke å se.`}</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Hvor skal foreldrene til Lise lete etter Mira?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'de leter i garasjen',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'de går en runde i nabolaget',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'de leter i hagen',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'de varsler til politiet',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>
					{`Det er da Lise kommer på det. Hun løper inn i huset igjen og opp trappene. Mira må
			ha gjemt seg på samme sted som sist, tenker Lise. Hun har helt sikkert ikke
			rømt, selv om pappa hadde glemt å lukke døren til huset igjen.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Hva er det Lise kommer på?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Mira må ha gjemt seg samme sted som sist',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'Mira har rømt',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Mira sover i kjelleren',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'Mira er hos dyrlegen',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>
					{`- Mira! roper Lise, men ingen svarer. Hun åpner døren til badet, og der ligger Mira. 
					Hun har sovet godt og logrer når Lise kommer inn.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Hva ser Lise når hun åpner badedøren?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'rommet er tomt',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'foreldrene',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Mira som har våknet',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'Mira som bjeffer',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>
					{`- Selvfølgelig er du her og varmer deg på badegulvet, sier Lise og stryker henne over magen.`}
				</p>
				<p>{`- Dette er jo typisk deg! Mira logrer og er glad hver gang Lise hilser på henne.`}</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Hvorfor er det typisk Mira å ligge på badet?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Mira liker å sove',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Mira liker å logre',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'Mira liker Lise',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'Mira liker varmen i gulvet',
					},
				],
			},
		],
	},
];
export const TextGrade4 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`BUE, PILER OG SVARTE DUER`}</h2>
				<p>
					{`
			Ailo gleder seg til skolen er ferdig. Mamma skal hjelpe ham å lage pil og bue! Visste du at indianerne brukte pil og bue? 
			De var flinke jegere. Indianerne brukte også spyd, men Ailo liker ikke spyd. 
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Hvorfor trengte indianerne pil og bue?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'de måtte beskytte seg',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'fordi de ikke likte å bruke spyd',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'de var flinke jegere',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'fordi mamma hadde sagt det',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>{`- Hvor er mamma? sa Ailo til pappa da han kom hjem.`}</p>
				<p>{`- Mamma må jobbe overtid, sa pappa. `}</p>
				<p>{`- Og vi som skulle lage pil og bue i dag! sa Ailo.`}</p>
			</span>
		),

		questions: [
			{
				id: 2,
				text: 'Hvorfor ble Ailo skuffet?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'pappa må jobbe overtid',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'faren vil ikke lage pil og bue',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'mamma må jobbe overtid',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'skolen er ikke ferdig',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>
					{`Det var i dag det ja, sa pappa. Han rørte i gryta og smakte på maten.`}
				</p>
				<p>{`- Ja, sa Ailo skuffet.Jeg vet at du har gledet deg.`}</p>
				<p>{`- Ja, jeg har gledet meg i hele dag. `}</p>
				<p>{`- Jeg liker pil og bue jeg også, sa pappa.`}</p>
				<p>{`- Ikke like mye som mamma. `}</p>
				<p>{`- Nei, kanskje ikke like mye som mamma. `}</p>
				<p>{`Pappa sa det bare for å trøste ham.`}</p>
			</span>
		),

		questions: [
			{
				id: 3,
				text:
					'Hvorfor mente Ailo at faren sa at han likte pil og bue han også?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'for å trøste ham',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'faren liker indianere',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'faren liker moren',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'faren liker våpen',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>
					{`De spiste middag, og pappa bablet om hvor god lapskausen var. Den var helt grei, men ikke kjempegod. 
			Etter maten kom mamma hjem fra jobb. Hun så sliten ut.`}
				</p>
			</span>
		),

		questions: [
			{
				id: 4,
				text: 'Hva bablet faren om?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'at han elsker varm mat',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'oppskriften til lapskausen',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'hva som hadde skjedd på jobb',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'hvor god middagen var',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>{`- Mamma! ropte Ailo, og ga henne en klem. `}</p>
				<p>{`- Hei, gullet mitt, sa mamma og kysset ham. `}</p>
				<p>{`- Skal vi lage pil og bue etter at jeg har spist? `}</p>
				<p>{`- Er du sikker på at du ikke er for sliten? sa Ailo. `}</p>
				<p>{`- Jeg er kjempesliten, men jeg har lovt å hjelpe deg.`}</p>
				<p>{`Mamma var den beste. Hun ville hjelpe Ailo selv om hun var så sliten.`}</p>
			</span>
		),

		questions: [
			{
				id: 5,
				text: 'Hvorfor var mamma den beste?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice:
							'fordi hun ville hjelpe Ailo selv om hun var så sliten',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'fordi hun ville lage pil og bue',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'fordi hun kalte ham ”gullet sitt”',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'fordi hun klemte ham',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>{`Da er jeg klar, sa mamma.`}</p>
				<p>{`- Jippi! sa Ailo, og løp etter henne til boden.`}</p>
				<p>{`Mamma var flink til å spikke. Hun spikket på en lang pinne som skulle bli buen. 
			Flere korte pinner skulle bli pilene. Ailo pusset buen og pilene med pussepapir, og mamma malte pilene med fine farger.`}</p>
				<p>{`- Da må alt få tørke til i morgen, så kan vi skyte på svarte leirduer, sa mamma.`}</p>
			</span>
		),

		questions: [
			{
				id: 6,
				text: 'Hvor lenge måtte malingen tørke?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'til mandag',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'i en time',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'til i morgen',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'i 24 timer',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>{`Plutselig kom pappa inn døren med et brak.`}</p>
				<p>{`- Hvordan går det med dere? sa pappa, og snublet.`}</p>
				<p>{`Han tråkket på buen og pilene så de knakk. Han falt på de svarte leirduene. Han knuste alt sammen!`}</p>
			</span>
		),

		questions: [
			{
				id: 7,
				text: 'Hva tråkket faren på?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'leirduene',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'pilene og buen',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'foten til Ailo',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'malingen',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>{`Oi, sann! sa pappa.`}</p>
				<p>{`- Ja, det kan du si, sa mamma. Hun gned seg i hodet. `}</p>
				<p>{`- Vi får starte på nytt igjen i morgen. `}</p>
				<p>{`- Ja, men da skal pappa gjøre alt arbeidet, sa Ailo.`}</p>
			</span>
		),

		questions: [
			{
				id: 8,
				text: 'Hvorfor mente Ailo at faren skulle gjøre alt arbeidet?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'fordi faren kom inn døren',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'fordi faren snublet og falt',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'fordi faren var en dust',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'fordi det var faren som hadde ødelagt alt',
					},
				],
			},
		],
	},
];

export const TextGrade5 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`GULLGRAVEREN`}</h2>
				<p>
					{`Ola skal bli gullgraver når han blir stor. Han øver på å grave hver eneste dag. En gang fant moren en gullring på et jorde.
			Den var verdt minst tusen kroner.
			Det var en ring fra vikingtiden. Hun måtte levere den inn til en arkeolog. Moren fikk tre hundre kroner i finnerlønn. `}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Hva var det moren fant en gang?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'tusen kroner',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'en gullring',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'tre hundre kroner',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'finnerlønn',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>
					{`Visste du at det finnes gull i Norge? En gang var det  noen som fant en klump som var verdt ti tusen kroner. I dag er hele familien på jakt etter gull i Finnmark. 
			De har fått lov til å vaske etter gull i elva Nytysjokka. Elva ligger i nærheten av Karasjok.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Hva er det Ola og familien skal gjøre i dag?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'besøke Karasjok',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'bade i Nytysjokka',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'gå på jakt',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'vaske etter gull',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>
					{`Moren og faren har startet å vaske sand i elva med en stor panne. 
					Pannen er svart og det gjør det lettere å se gull som glitrer.`}
				</p>
				<p>
					{`Ola har vasket gull i tre timer nå. Han har funnet fem små korn, men han jakter på gullklumper. 
					Ola vasker og vasker, men finner ikke mer. Moren og faren finner ikke noe de heller.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Hva er det Ola jakter på?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'gullkorn',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'gullklumper',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'en stor panne',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'elg',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>
					{`Det begynner å bli mørkt, og foreldrene pakker sammen tingene de har. De får ikke lov til å lete etter midnatt. 
					
					`}
				</p>
				<p>{`- Vi drar nå! roper faren. `}</p>
				<p>{`- Jeg kommer! roper Ola.`}</p>
				<p>{`Klokka er kvart på tolv. Ola har fortsatt en sjanse til å finne en gullklump.`}</p>
				<p>{`- Vi venter i bilen, sier moren.`}</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Hvorfor må familien pakke sammen og dra?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'det begynner å bli mørkt',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'de får ikke lete etter midnatt',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'de er trøtte',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'flyet går snart',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>
					{`
			Ola tusler ned til elva, og går til stedet der elva svinger brått. Han har lest at gull er tyngre enn stein. 
			Der vil gullet miste fart og synke til bunns. Pannen er full. Ola plukker ut de største steinene, og vasker vekk det øverste laget med sand. 
			Det er ikke noe som glitrer, så han vasker videre.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Hvorfor leter Ola der elva svinger brått?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'strømmen er ikke så sterk',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'vannet er varmere der',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'fordi gull er tyngre enn stein',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'gullet synker til bunns der',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>{`- Nå må du komme! roper faren. Moren står ved bilen med en lommelykt.`}</p>
				<p>{`- Jeg kommer! roper Ola. `}</p>
				<p>{`Idet Ola skal til å gå, ser han det glitre i bunnen av elva. Vannet er grumsete, så han må være rask.
			Han bøyer seg ned og griper etter det som glitrer. Hånden er fylt med sand og småsteiner.`}</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Hva ser Ola i vannet?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'noe som glitrer',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'grumsete vann',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'sand',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'småstein',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>
					{`
			Ola vasker forsiktig vekk sanden, og det er nå han ser gullklumpen. 
			Den er fire centimeter lang og mye tyngre enn steinen som er litt større.
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Hvor lang er gullklumpen?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: '4 liter',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: '4 decimeter',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: '4 centimeter',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: '4 millimeter',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>{`- Herregud, jeg har funnet gull! `}</p>
				<p>{`- Klokka er over midnatt, roper faren. - Vi har ikke lov til å lete etter tolv!`}</p>
				<p>{`- Jeg kommer, sier Ola.`}</p>
				<p>{`- Fant du noe? spør moren. `}</p>
				<p>{`- Ja, sjekk den gullklumpen!`}</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Hvem er det som lurer på om Ola fant noe?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'faren',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'moren',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'begge foreldrene',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'bestemoren',
					},
				],
			},
		],
	},
];
export const TextGrade6 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`SPRELSKE SPØKELSER`}</h2>
				<p>
					{`Annie liker sprelske spøkelser. Hun har sett minst tusen stykker! På loftet er det kjempemange sprelske spøkelser hver eneste kveld, 
			og de hvisker, hyler, uler og jamrer. De bråker så fælt at faren ikke får sove. 
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Hvorfor får ikke faren sove?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Annie holder ham våken',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'spøkelsene bråker så fælt',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'unger ringer på og stikker av',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'han er ikke trøtt',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>
					{`Annie kan se spøkelser. Det har hun gjort siden  hun var fem år. Nå er hun elleve år, og stor. Annie  syns det er kjempekult at de lager så mye sprell.  
			Det er som regel hun som får skylden for alt bråket,  men faren har trodd på henne i det siste. Nå skylder  han på spøkelsene. 
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Hvem skyldte faren på for alt bråket før?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'spøkelsene',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'Annie',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'søsteren',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'naboen',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>
					{`Det er halloween i dag, og Annie har brukt  kvelden på å gå på dørene. Hun har fått fem kilo med  smågodt, og rare kaker som naboen Lotte har bakt.  
			I år hadde hun bakt heksefingre med negler av mandel. `}
				</p>
				<p>{`Annie er skikkelig sliten, så hun legger seg under dyna. Det er nå moroa starter. Fem sprelske 
			spøkelser dukker opp fra under sengen. De jåler og lager grimaser for å prøve å skremme henne, men hun er vant til bråket.`}</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Hva slags moro er det som starter nå?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Annie får smågodt av naboene',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'spøkelsene jager Annie',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'spøkelsene lager grimaser til faren',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'spøkelsene dukker opp',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>
					{`Annie snur seg rundt og later som om hun har sovnet. Spøkelsene skjønner at de ikke får oppmerksomhet. De tror hun sover, og akkurat da snur hun seg brått.
					
					`}
				</p>
				<p>{`- Bø! hyler Annie.`}</p>
				<p>{`De sprelske spøkelsene skvetter til, og flyr rundt i rommet som vettskremte småfugler.`}</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Hvordan får Annie spøkelsene til å forsvinne?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'hun later som hun sover',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'hun skremmer dem',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'hun har vinduet åpent',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'hun ringer en prest',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>{`Akkurat idet den siste forsvinner inn under sengen, kommer faren inn på rommet.`}</p>
				<p>{`- Hva er det som foregår? sier faren.`}</p>
				<p>{`- Ingenting, sier Annie, og later som om hun nettopp ble vekket. Hun gjesper høyt. `}</p>
				<p>{`- Hva var det jeg hørte, da? sier faren. `}</p>
				<p>{`- Det var sikkert de sprelske spøkelsene igjen, sier hun, og snur seg mot veggen.`}</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text:
					'Hvorfor tror du at Annie later som om hun nettop ble vekket når faren kommer inn på rommet?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'hun later ikke som',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'hun vil ikke få skylden for bråket',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'hun vil ha ro og fred',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'hun vil ikke skremme faren',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>{`- Er du sikker på at det var spøkelser?`}</p>
				<p>{`- Jeg sover, pappa! sier Annie, og trekker dyna over hodet.`}</p>
				<p>{`- Ja, vel. God natt, sier han, og lukker døren etter seg. `}</p>
				<p>{`- God natt! mumler Annie, og lukker øynene. `}</p>
				<p>{`Hun må sove nå. Hun er stuptrøtt, og ingen sprelske spøkelser skal få vekke henne.`}</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Hvem er det som ikke skal få vekke Annie?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'faren',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'søsteren',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'spøkelsene',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'bestefar',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>
					{`Akkurat idet Annie holder på å sovne, hører hun et rabalder inne på soverommet til faren. Ut fra  lyden så er det akkurat det samme som skjedde i
					 går. Det rare er at han ikke lærer. En skal ikke gi spøkelser oppmerksomhet. Det er da de synes det er morsomt å tulle med oss.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Hva hører Annie fra soverommet til faren?',
				choices: [
					{
						letter: 'a',
						identity: 'c',
						choice: 'bråk',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'snorkelyder',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'kjefting',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'musikk',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>
					{` Hjelp! hyler faren og slenger ting i veggene. Spøkelsene tør ikke komme inn til Annie i natt, 
					men de bråker gjerne med ham,
					 for han blir så sint og redd.`}
				</p>
				<p>{`- God natt, pappa! roper Annie. - Sov godt, selv om du aldri lærer!`}</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Hva er det faren aldri lærer?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'matematikk',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'å skremme spøkelser',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'han må ikke gi spøkelsene oppmerksomhet',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'ikke bli redd',
					},
				],
			},
		],
	},
];
export const TextGrade7 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`FLAKS ELLER UFLAKS?`}</h2>
				<p>
					{`Morten harket og hostet opp sjøvann på en øde øy et sted i Stillehavet, og lurte på om han var død. Det eneste han husket var at kapteinen på turistskipet hadde bråsnudd  båten, 
			idet han hadde lent seg over båtrekka.`}
				</p>
				<p>
					{`Øya var stor, men Morten hadde ikke energi til å  utforske den. 
			Han hadde ikke spist siden i går.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Hvordan havnet Morten i sjøen?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'han stupte',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'han falt i sjøen på en øde øy',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'han falt over rekka på båten',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'han var på svømmetur',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>
					{`Et passasjerfly gled forbi over skyene. Morten skrev HJELP!” i store bokstaver i sanden, så flyene kanskje kunne se det. 
			Han brukte ventetiden til å kakke hull i kokosnøtter, så han fikk i seg næring. Det var bare kjempeflaks som gjorde at han hadde strandet på en øde øy med kokospalmer.
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Hvorfor var det flaks at det var kokospalmer der?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'han kunne klatre opp og se utover havet',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'han kunne speide etter fly',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'han fant næring',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'fordi det er rovdyr der om natta',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>
					{`Dagene gikk, og flyene passerte som de alltid gjorde, og Morten ble bare tynnere og tynnere. Etter sekstifem dager, hadde han fortsatt bare kokosmelk å drikke, og fisk klarte han ikke å fange.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Hvorfor ble Morten tynnere og tynnere?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'han spiste feil mat',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'han fant bare drikke',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'han trente mye',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'han ville ikke spise',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8?`}</h2>
				<p>
					{`Det var flaks at Morten strandet, men uflaks at øya var øde og forlatt. 
			Han måtte gjøre mer for å prøve å skaffe hjelp, så han fant en tomflaske som lå blant drivved og rask. 
			Han skrev på en tøybit. Tøybiten stappet han inn i flasken og satte på korken. 
			Det var verdt et forsøk. Morten kastet flasken så langt han kunne ut i sjøen, så måker og andre sjøfugler flakset i alle retninger. Nå måtte han bare vente.
			`}
				</p>
				<p>
					{`Flere dager gikk uten at noe nytt skjedde. Flyene fløy forbi som de alltid hadde gjort. Fuglene fløy og flakset rundt som om ingenting hadde skjedd.
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Hva gjorde Morten med flasken?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'fylte den med vann',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'han knuste den',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'han drakk av den',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'brukte den som flaskepost',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>
					{`Neste morgen, åttini dager etter at hadde strandet på den øde øyen, hørte Morten en motordur i det fjerne. En liten båt med påhengsmotor, harket og hostet ut svart røyk ute på bølgene.
			`}
				</p>
				<p>
					{`- Hjelp! ropte Morten, og løp til strandkanten. Mannen i båten oppdaget ham, og vinket tilbake. Så styrte han motorbåten mot sandstranden.
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Hvor kom motorduren fra?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'fra skogen',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'luften',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'fra sjøen',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'fra det fjerne østen',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>{`- Hei! ropte mannen.`}</p>
				<p>{`- Hei! ropte Morten tilbake. `}</p>
				<p>{`- Hva er det du roper om hjelp for, unge mann? sa mannen i motorbåten.`}</p>
				<p>{`- Herregud jeg er så glad for å se deg, sa Morten og kastet seg rundt halsen hans, og ga ham en lang klem.`}</p>
				<p>{`- Ta det med ro. Er alt bra ? `}</p>
				<p>{`- Nei, jeg har jo strandet på denne øde øya, sa Morten fortvilet.`}</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Hva er det mannen i båten lurer på?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'hvorfor Morten roper om hjelp',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'om Morten er sulten',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'om Morten er tørst',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'om Morten liker seg på en øde øy',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>
					{`Øde øy? Dette er absolutt ingen øde øy, fortsatte mannen.
			Det er jo et gigantisk stort hotell like bak skogholtet der.`}
				</p>
				<p>{`- Hva er det du sier? sa Morten overrasket.`}</p>
				<p>{`- Stakkars. Du trodde du var på en øde øy, du! `}</p>
				<p>{`Det var uflaks, men flaks at det er et turisthotell her da, så du kan få slappet av litt.`}</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Hva er like bak skogholtet?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'hav',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'et turisthotell',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'et fjell',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'et gigantisk stort troll',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>{`Morten nikket kort, men tenkte mest på hvor utrolig dum han hadde vært.`}</p>
				<p>{`- Du ser tørst ut. Vil du ha ett par iskalde flasker med deilig brus?`}</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Hvorfor mente Morten at han hadde vært dum?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'fordi han hadde strandet på en øde øy',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'fordi han ikke hadde ringt familien',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'fordi han ikke hadde undersøkt øya',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'fordi han kunne tatt båten',
					},
				],
			},
		],
	},
];

export const text = (
	<span>
		<h2 className="text-center">{`Vendepunktet`}</h2>
		<p>
			{`«Noah har mange venner.
			Abdi har mange venner han også. Ikke alle har venner.
			Pål har ingen venner. Pål er mye alene. Noah går på besøk til Pål i dag.
			Han er snill og flink. Det er ingen grunn til at Pål ikke skal ha venner.
			Pål kan bli venn med Noah. Noah vil ha nye venner. Noen samler på ting. Noah samler på venner.
			Pål har mange spill. Han har også puslespill, brio og dam.`}
		</p>
		<p>
			{`Mamma hadde blitt oppringt av senterlederen mens jeg var på vei 
			hjem. Det var forsvunnet ting fra butikken jeg hadde vært på. Jeg 
			er akkurat kommet inn døra hjemme når jeg blir konfrontert med 
			anklagene. «Jeg har ikke tatt noe», svarer jeg. «Det er bare å 
			sjekke alt jeg har på meg. Jeg har ikke tatt noe».`}
		</p>
		<p>
			{`Og jeg vet at de ikke vil finne noe hverken i skolesekken eller i 
			lommene. Mamma tømmer ut alt innholdet i sekken mens pappa saumfarer 
			jakkelommene med brå bevegelser. «Hvor har du fått tak i denne?» 
			spør mamma mens hun tar ut én og én ting fra sminkebagen min. «Den 
			der kjøpte jeg for tre måneder siden, minst. Ser du ikke at den 
			snart er oppbrukt?» svarer jeg. Og sånn holder vi på til alt fra 
			sminke til gymtøy er gjennomgått. Ingenting av det var nytt, og jeg 
			kunne gjøre rede for alt. Mamma og pappa veksler blikk, og jeg aner 
			en viss usikkerhet hos dem.`}
		</p>
		<p>
			{`«Er det noe du vil fortelle oss? Nå har du sjansen til å fortelle 
			oss sannheten. Vi vil høre den fra deg selv, ikke fra andre, om du 
			har gjort noe dumt» sier pappa. «Du ser jo selv at jeg ikke har tatt 
			noe», svarer jeg. «Senterlederen sier at de har sterk mistanke om at 
			du har stålet. De ringer jo ikke bare på tull og påstår noe så 
			alvorlig? Om du lyver for oss nå, så er det et alvorlig 
			tillitsbrudd» fortsetter han.`}
		</p>
		<p>
			{`Nå kjenner jeg ikke bare på angsten, men også på fortvilelsen over 
			at de ikke tror på meg, enda de ikke finner noe. At de tror mer på 
			andre enn på meg. Dette er en helt ny opplevelse. Jeg vil gråte, men 
			er samtidig så redd at gråten ikke kommer ut.`}
		</p>
		<p>
			{`«Senterlederen vil gjerne snakke med oss, så vi skal dra dit nå», 
			sier mamma. «Men skal vi ikke spise først?» spør jeg enda jeg ikke 
			er sulten i det hele tatt. Middagslukten fra kjøkkenet gir likevel 
			håp om at alt kan settes på pause. Jeg føler meg uvel. Selv ikke 
			tikka masala, yndlingsretten min, frister akkurat nå. «Middagen får 
			vente», svarer mamma. «Vi må få denne saka ut av verden så fort som 
			mulig».`}
		</p>
		<p>
			{`Det er merkelig å kjøre samme veien tilbake som jeg for under en 
			time siden hadde kjørt med bussen. Jeg hadde vært en kjapp tur innom 
			kjøpesenteret etter skoletid, og tok en milkshake sammen med noen fra 
			klassen. Det er stille i bilen. Jeg har musikken på lavt så jeg kan 
			høre hvis de snakker seg imellom. Men de veksler ikke et ord. Jeg 
			prøver å lese ansiktet til mamma i sidespeilet, mens jeg kan studere 
			pappa mer direkte fra baksetet. Han ser veldig alvorlig og 
			konsentrert ut der han kjører. Han er sikkert veldig lettet for at 
			de ikke fant noe. Begge tenker sikkert på hva de skal si på møtet.`}
		</p>
		<p>
			{`Inne på senteret går vi inn ei grønn dør som er skiltet med 
			«Ledelse». Jeg har knapt lagt merke til denne tidligere, enda jeg 
			har gått forbi der flere ganger på vei til toalettet. Ei 
			securitasvakt spør om vi skal møte senterlederen, og viser oss vei. 
			Vi kommer inn i en liten korridor med mange dører, og i enden står 
			ei dør på gløtt. Inne på kontoret sitter to damer og en annen 
			securitasvakt. Hun ene har jeg aldri sett på ordentlig, bare i 
			media. Hun har langt lyst hår, knallrøde negler og lepper, og er 
			stilig kledd. Hun andre kjenner jeg igjen fra butikken. Hun har 
			brunt hår som er samlet i en lang hestehale, og er ganske pen. 
			Securitasvakta kjenner jeg også igjen. Han bruker noen ganger å gå 
			rundt, og noen ganger bruker han å sitte i service-skranken midt 
			inne i senteret i 1.etasjen.`}
		</p>
		<p>
			{`Vi setter oss rundt et stort møtebord, og senterlederen innleder 
			med å fortelle at det alltid er like trist når unge mennesker blir 
			tatt for butikktyveri. «Vi anmelder samtlige fordi det ellers vil 
			være umulig å velge hvem som skal anmeldes og hvem som skal slippe». 
			Butikkdama tar ordet og sier at «Et mammahjerte brister når jeg må 
			anmelde noen som er tatt for første gang. Det er veldig vondt. Men 
			hva skal jeg gjøre? Jeg må selv betale for å få varer inn til 
			butikken min, og jeg må fortsatt betale lønn til de ansatte og 
			betale for leie av butikklokalene – selv om folk stjeler og jeg 
			taper inntekt. Det er så jævlig urettferdig at jeg skal lide for 
			at enkelte ikke skjønner forskjellen på mitt og ditt».  `}
		</p>
		<p>
			{`Nå er det securitasvakten som tar ordet: «Etter mange års erfaring 
			kan vi se potensielle butikktyver. Det handler om hvordan de 
			oppfører seg i butikken, selv om de tror at de oppfører seg helt 
			normalt. Og mens gutter typisk stjeler alene, så stjeler jentene 
			når de er sammen med andre. Et vanlig eksempel er når tre venninner 
			er inne samtidig og to gjør hva de kan for å avlede oppmerksomheten 
			mens den tredje stjeler. Da vet vi at det er en vel gjennomtenkt 
			handling». Senterlederen overtar ordet, og veksler mellom å se på 
			meg og foreldrene mine: «Noen ganger blir én person i en vennegjeng 
			tatt. Men vi vet hvem de andre er. Da vil også de andre vennene bli 
			møtt med mistenksomhet neste gang de er i butikkene våre, selv om de 
			er uskyldige. Alle blir stemplet dersom de er i venneflokken til en 
			butikktyv».`}
		</p>
		<p>
			{`Jeg aner nå et lite smil hos mamma. Hun vet at dette ikke angår 
			meg. Jeg opptrer ikke i noen gjeng. Selv om vi har bodd på dette 
			stedet i to år nå, har det vært vanskelig å få nye venner. Dronninga 
			i klassen og hoffet hennes bitch-blikket meg ganske lenge, og guttene 
			er noen skikkelige drama queens som sprer falske rykter om folk. 
			Heldigvis har det blitt bedre i det siste, og jeg har liksom blitt 
			mer akseptert. I morgen kveld skal jeg til og med få komme hjem på 
			filmkveld hos dronninga. Men jeg savner gammelklassen.`}
		</p>
		<p>
			{`«Uansett om den vi fersker er fylt 15 år eller ikke, så anmelder 
			vi saka til politiet», fortsetter senterlederen. «Du blir da 
			registrert som anmeldt, og må møte hos politiet. Barnevernet blir 
			ofte også kontaktet».`}
		</p>
		<p>
			{`Det virker som de har snakket om dette mange ganger før. 
			Senterlederen, butikksjefen og securitasvakten snakker ikke i munnen 
			på hverandre, men de overtar for hverandre som i et stafettløp. Jeg 
			hører på måten mamma og pappa puster på at de har tenkt å si noe nå, 
			de også. Men før noen av dem kommer så langt, fortsetter 
			securitasvakten mens han ser på meg: «Hvis du er fylt 15 kan du bli 
			straffet med bøter. Da blir du registrert som straffet person, men i 
			enkelte tilfeller kan du være heldig å bare få påtaleunnlatelse. Det 
			vil si at du er skyldig, men ikke får straff denne gangen».`}
		</p>
		<p>
			{`Senterlederen ser på pappa, selv om det er meg hun snakker til: 
			«Husk at hvis du blir tatt for å stjele kan også mulighetene dine 
			videre i livet bli begrenset. Du kan få utsettelse på når du kan ta 
			mopedførerbeviset og førerkortet, i tillegg kan du få problemer med å 
			få jobber eller studier som krever sikkerhetsklarering». Så flytter 
			hun blikket til meg og spør: «Er du fylt 15?».`}
		</p>
		<p>
			{`Nå har pappa fått nok. Han vrir seg i stolen og kremter høyt. 
			Stemmen hans skjelver når han begynner å prate, og det gjør den bare 
			når han er sint. «Og dere er kanskje også kjent med at det er 
			straffbart å fremsette falske beskyldninger og fabrikkere bevis? Er 
			det ikke tre år som er strafferammen for slikt? Det skal jeg nok 
			finne ut av». Det blir plutselig en annen temperatur i rommet. 
			Senterlederen prøver å si noe, men blir avbrutt av pappa. «Vi 
			ransaket skolesekken og jakkelommene hennes med det samme hun kom 
			inn døra hjemme. Fra vi ble varslet og til hun kom hjem gikk det 
			veldig kort tid, og hun hadde ikke hatt sjans til å gjemme noe. Og 
			bare så det er sagt så fant vi ingenting som ikke er hennes. 
			Ingenting! Derfor er det veldig merkelig å sitte og høre på alt dere 
			lirer av dere – uten at dere har noe som helst belegg for det dere 
			beskylder henne for».`}
		</p>
		<p>
			{`Jeg kjenner på en enorm lettelse. Mamma og pappa tror på meg 
			likevel. Og pappa har jo helt rett. Det er ingen som har tatt meg 
			med stjålne ting`}
		</p>
		<p>
			{`Selv om det ennå er seint på ettermiddagen, føles det som at det 
			er seint på kvelden. Det har skjedd så mye. Jeg rekker å kjenne på 
			at jeg er sulten, at jeg gleder meg til å komme hjem og spise, og at 
			det er godt at det er helg. Men så kommer Securitasvakta med ordene 
			som får hele verden min til å falle i grus. `}
		</p>
		<p>
			{`«Vi har bevis. Ikke bare fra i dag, men også fra to tidligere 
			tilfeller, og i ulike butikker. Som sagt så stjeler jenter helst i 
			flokk. Men det har ikke skjedd i dette tilfellet. Vi har hatt 
			mistanke over tid, og mistanken vår er blitt fanget opp og bekreftet 
			gjennom opptak fra våre overvåkningskamera».`}
		</p>
		<p>
			{`Kroppen er paralysert. Ansiktet er helt numment, som om jeg har 
			mistet alt blodet. Mamma og pappa ser på meg med vantro blikk. 
			Stemmene i rommet er langt borte. Det er som at jeg ikke skjønner 
			hva som skjer rundt meg. Jeg kjenner på skammen både for det jeg har 
			gjort og for at jeg har løyet til dem. Og oppi alt kjenner jeg på en 
			lettelse.`}
		</p>
	</span>
);

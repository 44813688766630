import audio1 from 'assets/sounds/no/diktat/sild.mp3';
import audio2 from 'assets/sounds/no/diktat/skjelven.mp3';
import audio3 from 'assets/sounds/no/diktat/godt.mp3';
import audio4 from 'assets/sounds/no/diktat/kyllinger.mp3';
import audio5 from 'assets/sounds/no/diktat/kjøtt.mp3';
import audio6 from 'assets/sounds/no/diktat/smarttelefon.mp3';
import audio7 from 'assets/sounds/no/diktat/fugl.mp3';
import audio8 from 'assets/sounds/no/diktat/sykkelhjelm.mp3';
import audio9 from 'assets/sounds/no/diktat/hjerte.mp3';
import audio10 from 'assets/sounds/no/diktat/kjeveortoped.mp3';
import audio11 from 'assets/sounds/no/diktat/blåbærsyltetøy.mp3';
import audio12 from 'assets/sounds/no/diktat/biblioteket.mp3';
import audio13 from 'assets/sounds/no/diktat/kjetting.mp3';
import audio14 from 'assets/sounds/no/diktat/sannsynlighet.mp3';
import audio15 from 'assets/sounds/no/diktat/abrakadabra.mp3';
import audio16 from 'assets/sounds/no/diktat/lov.mp3';
import audio17 from 'assets/sounds/no/diktat/enn.mp3';
import audio18 from 'assets/sounds/no/diktat/unnskyld.mp3';
import audio19 from 'assets/sounds/no/diktat/kroppstemperatur.mp3';
import audio20 from 'assets/sounds/no/diktat/reportasje.mp3';
import audio21 from 'assets/sounds/no/diktat/skylder.mp3';
import audio22 from 'assets/sounds/no/diktat/også.mp3';
import audio23 from 'assets/sounds/no/diktat/selvfølge.mp3';
import audio24 from 'assets/sounds/no/diktat/uværet.mp3';
import audio25 from 'assets/sounds/no/diktat/kjedelig.mp3';

const audioFiles = {
	1: audio1,
	2: audio2,
	3: audio3,
	4: audio4,
	5: audio5,
	6: audio6,
	7: audio7,
	8: audio8,
	9: audio9,
	10: audio10,
	11: audio11,
	12: audio12,
	13: audio13,
	14: audio14,
	15: audio15,
	16: audio16,
	17: audio17,
	18: audio18,
	19: audio19,
	20: audio20,
	21: audio21,
	22: audio22,
	23: audio23,
	24: audio24,
	25: audio25,
};

export default audioFiles;

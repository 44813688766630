import bananaWrong from 'assets/sounds/nn/banana-is-wrong.mp3';
import arrowWrong from 'assets/sounds/nn/arrow-is-wrong.mp3';
import tutorial6Sound from 'assets/sounds/no/Bytt-tutorial-6.mp3';
import doorWrong from 'assets/sounds/nn/door-is-wrong.mp3';
import fireCorrect from 'assets/sounds/nn/fire-is-correct.mp3';
import sunWrong from 'assets/sounds/nn/sun-is-wrong.mp3';
import jetWrong from 'assets/sounds/nn/jet-is-wrong.mp3';
import carWrong from 'assets/sounds/nn/car-is-wrong.mp3';
import tutorial3Sound from 'assets/sounds/nn/Bytt-tutorial-3.mp3';
import lockCorrect from 'assets/sounds/nn/lock-is-correct.mp3';

import catWrong from 'assets/sounds/nn/cat-is-wrong.mp3';
import kombCorrect from 'assets/sounds/nn/komb-is-correct.mp3';
import readingWrong from 'assets/sounds/nn/lese-is-wrong.mp3';
import lightWrong from 'assets/sounds/nn/lys-is-wrong.mp3';
import tutorial1Sound from 'assets/sounds/nn/Bytt-tutorial-1.mp3';

import arrowImg from '../assets/Pil.PNG';
import blueImg from '../assets/blue.png';
import skyImg from '../assets/sky.png';
import greenImg from '../assets/green.png';
import yellowImg from '../assets/yellow.png';
import redImg from '../assets/red.png';
import purpleImg from '../assets/purple.png';

import cowImg from '../assets/cow.png';
import bulbImg from '../assets/bulb.png';
import mittenImg from '../assets/mittens.png';
import pencilImg from '../assets/pencil.png';
import puppyImg from '../assets/Hund.PNG';
import lamb from '../assets/Lam.PNG';
import komb from '../assets/Kam.PNG';
import light from '../assets/Lys.PNG';
import reading from '../assets/Lese.PNG';
import cat from '../assets/Katt.PNG';
import boat from '../assets/Båt.PNG';
import car from '../assets/Bil.PNG';
import plane from '../assets/Fly.PNG';
import sun from '../assets/Sol.PNG';
import fire from '../assets/Bål.PNG';
import door from '../assets/Dør.PNG';
import lock from '../assets/Lås.PNG';
import banana from '../assets/Banan.PNG';

import starterTesten from 'assets/sounds/nn/3 13 Tulleord Starter testen.m4a';
import submitTestAnswers from 'api/submitTestAnswers';

export const starterTestenSound = starterTesten;

export const bytteLydenExample = {};

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'Lys',
		},
		{
			word: 'Lese',
		},
		{
			word: 'Katt',
		},
		{
			isCorrect: true,
			word: 'Kam',
		},
	],
};
export const assignment1 = [
	{
		id: 1,
		mainImg: lamb,
		mainSound: tutorial1Sound,
		text:
			'Kva ord får du hvis du byter den første lyden /l/ i lam med lyden /k/? ',
		choices: [
			{
				remark: false,
				word: 'Lys',
				comment: 'Lys er dessverre feil. Prøv på nytt',
				sound: lightWrong,
				img: light,
			},
			{
				remark: true,
				word: 'Kam',
				comment: `Kam er heilt rett. Når du byter /l/ i lam med /k/, så blir det nye ordet «kam».`,
				sound: kombCorrect,
				img: komb,
			},
			{
				remark: false,
				word: 'Katt',
				comment: 'Katt er dessverre feil. Prøv på nytt',
				sound: catWrong,
				img: cat,
			},
			{
				remark: false,
				word: 'Lese',
				comment: 'Lese er dessverre feil. Prøv på nytt',
				sound: readingWrong,
				img: reading,
			},
		],
	},
];

export const assignment2 = [
	{
		id: 2,
		mainSound: tutorial3Sound,
		text:
			'Kva ord får du hvis du byter den siste lyden /t/ i båt med lyden /l/? ',
		mainImg: boat,
		choices: [
			{
				remark: false,
				word: 'Bil',
				comment: 'Bil er dessverre feil. Prøv på nytt',
				sound: carWrong,
				img: car,
			},
			{
				remark: false,
				word: 'Fly',
				comment: 'Fly er dessverre feil. Prøv på nytt',
				sound: jetWrong,
				img: plane,
			},
			{
				remark: false,
				word: 'Sol',
				comment: 'Sol er dessverre feil. Prøv på nytt',
				sound: sunWrong,
				img: sun,
			},
			{
				remark: true,
				word: 'Bål',
				comment:
					'Bål er heilt rett. Når du byter /t/ i båt med /l/ så blir det nye ordet «bål».',
				sound: fireCorrect,
				img: fire,
			},
		],
	},
];
export const assignment3 = [
	{
		id: 3,
		mainImg: bulbImg,
		mainSound: tutorial6Sound,
		text:
			'Kva ord får du hvis du byter den midtarste lyden /y/ i lys med lyden /å/? ',
		choices: [
			{
				remark: false,
				word: 'Arrow',
				comment: 'Pil er dessverre feil. Prøv på nytt',
				sound: arrowWrong,
				img: arrowImg,
			},

			{
				remark: false,
				word: 'Banana',
				comment: 'Banan er dessverre feil. Prøv på nytt',
				sound: bananaWrong,
				img: banana,
			},
			{
				remark: false,
				word: 'Door',
				comment: 'Dør er dessverre feil. Prøv på nytt',
				sound: doorWrong,
				img: door,
			},
			{
				remark: true,
				word: 'Lock',
				comment:
					'Lås er heilt rett. Når du byter /y/ i «lys» med /å/, så blir det nye ordet «lås»',
				sound: lockCorrect,
				img: lock,
			},
		],
	},
];
export const assignment4 = [
	{
		id: 4,
		mainImg: puppyImg,
		choices: [
			{
				remark: true,
				word: 'modil',
				comment: '',
				sound: carWrong,
				img: redImg,
			},
			{
				remark: true,
				word: 'vuks',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				remark: true,
				word: 'fålk',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
			{
				remark: true,
				word: 'flynt',
				comment: '',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
];
export const assignment5 = [
	{
		id: 2,
		mainImg: puppyImg,
		choices: [
			{
				word: 'modil',
				comment: '',
				sound: carWrong,
				img: redImg,
			},
			{
				word: 'vuks',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'fålk',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'flynt',
				comment: '',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
];

export const assignments = [
	{
		id: 2,
		mainImg: puppyImg,
		choices: [
			{
				word: 'modil',
				comment: '',
				sound: carWrong,
				img: redImg,
			},
			{
				word: 'vuks',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'fålk',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'flynt',
				comment: '',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
	{
		id: 3,
		mainImg: cowImg,
		choices: [
			{
				word: 'vorr',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'blastell',
				comment: '',
				sound: carWrong,

				img: yellowImg,
			},
			{
				word: 'peano',
				comment: '',
				sound: carWrong,
				img: skyImg,
			},
			{
				word: 'knain',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
		],
	},
	{
		id: 4,
		mainImg: pencilImg,
		choices: [
			{
				word: 'vorr',
				comment: '',
				sound: carWrong,
				img: purpleImg,
			},
			{
				word: 'blastell',

				comment: '',
				sound: carWrong,
				img: yellowImg,
			},
			{
				word: 'peano',
				comment: '',
				sound: carWrong,
				img: skyImg,
			},

			{
				word: 'brillter',
				comment: '',
				sound: carWrong,
				img: redImg,
			},
		],
	},
	{
		id: 5,
		mainImg: bulbImg,
		choices: [
			{
				word: 'vorr',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'blastell',
				comment: '',
				sound: carWrong,
				img: purpleImg,
			},

			{
				word: 'knain',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'brillter',
				comment: '',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
	{
		id: 6,
		mainImg: mittenImg,
		choices: [
			{
				word: 'vorr',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'blastell',
				comment: '',
				sound: carWrong,
				img: yellowImg,
			},

			{
				word: 'knain',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'brillter',
				comment: '',
				sound: carWrong,
				img: redImg,
			},
		],
	},
];

import React from 'react';
import {connect} from 'react-redux';
import './styles.scss';
import Logo from 'assets/logo-white.png';
import {isTablet} from 'react-device-detect';
import Gif from 'assets/rotation-from-horizontal-to-vertical.gif';
import {begin} from 'utils/beforeunload';
import strings from 'utils/strings';
import Introduksjon from 'components/Introduksjon';
import Orddiktat from 'components/Orddiktat';
import Ordkjedetesten from 'components/Ordkjedetesten';
import Tulleordtesten from 'components/Tulleordtesten';
import Skrivehastighet from 'components/Skrivehastighet';
import Leseproven from 'components/Leseproven';
import Ordforstaelse from 'components/Ordforstaelse';
import Ferdig from 'components/Ferdig';
import getTestInfo from 'api/getTestInfo';
import getUserInfo from 'api/getUserInfo';
import {setTestCandidateGrade} from 'actions/index';

const testSteps = [
	Introduksjon,
	Tulleordtesten,
	Ordkjedetesten,
	Skrivehastighet,
	Ordforstaelse,
	Leseproven,
	Orddiktat,
	Ferdig,
];
const testStepsWithoutReading = [
	Introduksjon,
	Tulleordtesten,
	Ordkjedetesten,
	Skrivehastighet,
	Ordforstaelse,
	Orddiktat,
	Ferdig,
];


var renderRemainingTests = [];
const renderWithoutLeseproven = [];
class ScreeningTest extends React.Component {
	constructor() {
		super();

		this.state = {
			isLoggedIn: false,
			needLanguageSetting: false,
			isPortrait: true,
			isRotateDeviceFirstTimeRendered: true,
		};
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);

		getTestInfo().then((testInfo) => {
			const exclusiveTests = testInfo.exclusiveTests;
			const excludeReading = testInfo.excludeReading;
			const grade = Number(testInfo.grade);
			this.props.onSetTestCandidateGrade(grade);
			const testToContinue = Object.values(exclusiveTests);
			if (testToContinue.length) {
				for (let i = 0; i < testToContinue.length + 1; i++) {
					switch (testToContinue[i]) {
						case 3:
						renderRemainingTests[0] = testSteps[1];
						break;
						case 2:
						renderRemainingTests[1] = testSteps[2];
						break;
						case 4:
						renderRemainingTests[2] = testSteps[3];
						break;
						case 6:
						renderRemainingTests[3] = testSteps[4];
						break;
						case 5:
						if (!excludeReading) {
						renderRemainingTests[4] = testSteps[5];
						}
						break;
						case 1:
						renderRemainingTests[5] = testSteps[6];
						break;
						default:
							break;
					}
					if (i === testToContinue.length) {
						renderRemainingTests = renderRemainingTests.filter(function (element) {
							return element !== undefined;
						});
						renderRemainingTests.push(Ferdig);
					}
				}
				this.setState({needLanguageSetting: true});
			}

			getUserInfo().then((response) => {
				if (response.ok) {
					this.setState({
						isLoggedIn: true,
						excludeReading
					});
					begin();
				} else {
					window.location.href = `/`;
				}
			});
		});
	}

	componentWillUnmount() {
		window.addEventListener('resize', this.handleResize);
	}

	handleResize = () => {
		if (window.innerHeight > window.innerWidth) {
			this.setState({
				isPortrait: true,
			});
		} else {
			this.setState({
				isPortrait: false,
			});
		}
	};

	onSelectLanguage(lang) {
		strings.setLanguage(lang);
		this.setState({
			needLanguageSetting: false,
		});
	}
	render() {
		const {isLoggedIn, isPortrait, isRotateDeviceFirstTimeRendered} =
			this.state;

		if (!isLoggedIn) {
			return null;
		}

		const {currentStepIndex, onSetTestCandidateGrade} = this.props;

		if (renderRemainingTests.length) {
			var CurrentStep = renderRemainingTests[currentStepIndex];
		}
		else if(this.state.excludeReading) {
			var CurrentStep = testStepsWithoutReading[currentStepIndex];
		}
		else {
			var CurrentStep = testSteps[currentStepIndex];
		}
		if (!isPortrait && isRotateDeviceFirstTimeRendered) {
			setTimeout(() => {
				this.setState({isRotateDeviceFirstTimeRendered: false});
			}, 3000);
		}
		return (
			<div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate logo"
						/>
					</h1>
				</header>
				{Boolean(renderRemainingTests.length) &&
					this.state.needLanguageSetting && (
						<div className="lit-test-intro">
							<h1>{`Velg/vel språk`}</h1>

							<button
								className="lit-test-intro-language-button"
								tabIndex="1"
								onClick={() => this.onSelectLanguage('no')}
							>
								{`Norsk Bokmål`}
							</button>
							<button
								className="lit-test-intro-language-button"
								tabIndex="2"
								onClick={() => this.onSelectLanguage('nn')}
							>
								{`Norsk Nynorsk`}
							</button>
						</div>
					)}
				{isRotateDeviceFirstTimeRendered && !isPortrait && isTablet && (
					<div className="lit-test-rotation-gif">
						<img src={Gif} alt="rotate tablet gif" />
					</div>
				)}
				{!this.state.needLanguageSetting && (
					<div className="lit-wrapper--article text-center">
						<CurrentStep />
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {currentStepIndex} = state;

	return {
		currentStepIndex,
	};
};
const mapDispatchToProps = {
	onSetTestCandidateGrade: setTestCandidateGrade,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreeningTest);

import classNames from 'classnames';
import Countdown from 'components/Countdown';
import React from 'react';
import {connect} from 'react-redux';
import {updateObject} from 'utils/reducerUtils';
import strings from 'utils/strings';
import questionsNn from './languages/nn-questions.json';
import * as textNn from './languages/nn-text';
import questionsNo from './languages/no-questions.json';
import * as textNo from './languages/no-text';

const questionsObj = {
	no: questionsNo,
	nn: questionsNn,
};

const textObj = {
	no: textNo,
	nn: textNn,
};

const getTestTextForGrade = (grade) => {
	let languageText;
	let textForGrade;
	switch (grade) {
		case 2:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade2;
			return textForGrade;

		case 3:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade3;
			return textForGrade;

		case 4:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade4;
			return textForGrade;

		case 5:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade5;
			return textForGrade;

		case 6:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade6;
			return textForGrade;

		case 7:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade7;
			return textForGrade;
	}
};
class Test extends React.Component {
	constructor(props) {
		super(props);

		this.allTestMaterial = getTestTextForGrade(this.props.candidateGrade);

		this.questions = questionsObj[strings.getLanguage()];
		this.testText = textObj[strings.getLanguage()];

		const results = {};

		/* for (let question of this.questions) {
			results[question.id] = '';
		} */

		for (let i = 0; i < this.allTestMaterial.length; i++) {
			const questions = this.allTestMaterial[i].questions;
			for (let j = 0; j < questions.length; j++) {
				const id = questions[j].id;
				results[id] = '';
			}
		}
		this.state = {
			results,
			timeRanOut: false,
		};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	render() {
		const {onNextStep, isSendingTestResults} = this.props;

		const {results, timeRanOut} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues || timeRanOut;

		return (
			<div className="lit-test lit-leseproven">
				<div className="lit-infobar">
					<Countdown
						duration={10 * 60}
						onComplete={() => {
							this.setState({
								timeRanOut: true,
							});
						}}
					/>
				</div>

				{this.allTestMaterial.map((testMaterial,index) => {
					const {para, questions} = testMaterial;

					return (
						<div key={index}>
							<div className="story">{para} </div>

							<div
								className={classNames({
									'lit-questions': true,
									'time-ran-out': timeRanOut,
								})}
							>
								<div className="questions-intro">
									{/* <h2 className="lit-page-title">
										Spørsmål til teksten
									</h2> */}
									<p>Trykk på riktig alternativ</p>
								</div>
								{questions.map((question) => {
									const {text, id} = question;
									const currentQuestionValue = results[id];
									const choices = question.choices;

									return (
										<div className="lit-question" key={id}>
											<span className="lit-input__label lit-input__label--count">
												{id}
											</span>
											<h4 className="question__text">
												{text}
											</h4>
											<div className="choices">
												{choices.map((choice) => {
													const choiceLetter =
														choice.letter;
													const mark =
														choice.identity;
													const idChoice =
														id + choiceLetter;
													return (
														<span
															className="lit-choice"
															key={idChoice}
														>
															<input
																checked={
																	currentQuestionValue ===
																	mark
																}
																className="lit-choice__field"
																disabled={
																	timeRanOut
																}
																id={idChoice}
																onChange={() =>
																	this.setResults(
																		id,
																		mark
																	)
																}
																type="radio"
															/>
															<label
																className="lit-choice__label"
																htmlFor={
																	idChoice
																}
															>
																<div className="checkbox" />
																{choice.choice}
															</label>
														</span>
													);
												})}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}

				{isNextButtonVisible && (
					<button
						className="lit-btn bg-primary"
						onClick={() => onNextStep(results)}
						disabled={isSendingTestResults}
					>{`Ferdig`}</button>
				)}
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<div className="lit-stick-to-bottom">
					<p>
						<a
							href="https://malimo.no/"
							target="_blank"
							rel="noopener noreferrer"
						>
							{'© Malimo'}{' '}
						</a>
					</p>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		testStepAnswers: {isSendingTestResults},
	} = state;

	return {
		isSendingTestResults,
	};
};

export default connect(mapStateToProps)(Test);

/* eslint-disable prefer-const */
import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {updateObject} from 'utils/reducerUtils';
import {isTablet} from 'react-device-detect';
import AudioPlayer from 'components/AudioPlayer';

import audioFilesNo from './languages/no-audioFiles';
import audioFilesNn from './languages/nn-audioFiles';
import nnAnswers from './languages/nnAnswers.json';
import noAnswers from './languages/noAnswers.json';
import audioFilesNoUpperGrades from './languages/no-audioFilesUpper';
import audioFilesNnUpperGrades from './languages/nn-audioFilesUpper';
import assignments from './languages/data.json';
import strings from 'utils/strings';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeHigh} from '@fortawesome/free-solid-svg-icons';

const audioFilesLowerGradeObj = {
	no: audioFilesNo,
	nn: audioFilesNn,
};
const audioFilesUpperGradeObj = {
	no: audioFilesNoUpperGrades,
	nn: audioFilesNnUpperGrades,
};

const testAnswerObj = {
	no: noAnswers,
	nn: nnAnswers,
};
const maxAudioPlayCount = 2;
class Test extends React.Component {
	constructor(props) {
		super(props);
		const selectedLanguage = strings.getLanguage() || 'no';
		this.audioFiles =
			this.props.candidateGrade < 5
				? audioFilesLowerGradeObj[selectedLanguage]
				: audioFilesUpperGradeObj[selectedLanguage];

		this.answers = testAnswerObj[selectedLanguage];
		const results = {};

		for (const assignment of assignments) {
			results[assignment.id] = assignment.value;
		}

		this.state = {
			results,
			disabledAssignments: {},
			audioPlayCounts: {},
			bruteFinish: false,
			showWordAudio: false,
			currentClickedIndex: null,
			showChoiceAudio: false,
			currentChoiceIndex: null,
			showQuestionPlayButton: true,
			showChoicePlayButton: true,
		};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	checkForConsecutiveMistake(id) {
		const {results} = this.state;

		const maxMistakeThreshold = this.props.candidateGrade < 5 ? 3 : 5;
		const answerKey =
			this.props.candidateGrade < 5 ? 'value' : 'upperGradeValue';

		let iterator = [];
		let checkConsitencyFlag = 0;
		if (id > 1) {
			// eslint-disable-next-line default-case
			switch (id) {
				case id:
					for (let i = 0; i < id; i++) {
						if (results[i + 1].trim() !== '') {
							if (
								this.answers[i][answerKey].toLowerCase() !==
								results[i + 1].toLowerCase()
							) {
								iterator.push(i);
							}
						}
					}

					if (iterator.length >= maxMistakeThreshold) {
						for (let i = 0; i < iterator.length; i++) {
							if (iterator[i + 1] !== 'undefined') {
								if (iterator[i + 1] - iterator[i] === 1) {
									checkConsitencyFlag =
										checkConsitencyFlag + 1;
									if (
										checkConsitencyFlag >=
										maxMistakeThreshold
									) {
										this.setState({
											bruteFinish: true,
										});
										break;
									}
								} else {
									checkConsitencyFlag = 0;
								}
							}
						}
					}

					break;
			}
		}
	}

	getDisabledAssignments() {
		const {results} = this.state;

		const disabledAssignments = {};
		for (let i = 0; i < assignments.length; i++) {
			const assignmentId = assignments[i].id;
			if (results[assignmentId]) {
				disabledAssignments[assignmentId] = true;
			}
		}
		return disabledAssignments;
	}

	render() {
		const {onNextStep, refs, isSendingTestResults} = this.props;

		const {audioPlayCounts, disabledAssignments, results} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues;

		return (
			<div className="lit-wrapper--thin lit-test lit-diktat">
				{!this.state.bruteFinish &&
					!isTablet && 
					assignments.map((assignment) => {
						const assignmentId = assignment.id;
						const audioFile = this.audioFiles[assignmentId];
						const assignmentAudioPlayCount =
							audioPlayCounts[assignmentId] || 0;
						const isDisabled = disabledAssignments[assignmentId];
						const isAudioDisabled =
							isDisabled ||
							assignmentAudioPlayCount === maxAudioPlayCount;

						let audioPlayerRef;
						return (
							<div
								className={classNames(
									'lit-input lit-revealed',
									{
										'lit-input--disabled': isDisabled,
									}
								)}
								key={assignmentId}
							>
								<AudioPlayer
									disabled={isAudioDisabled}
									id={assignmentId}
									onStart={() => {
										this.setState({
											audioPlayCounts: updateObject(
												audioPlayCounts,
												{
													[assignmentId]:
														assignmentAudioPlayCount +
														1,
												}
											),
										});
									}}
									ref={(ref) => {
										audioPlayerRef = ref;
									}}
									refs={refs}
									src={audioFile}
								/>
								<input
									className="lit-input__field"
									disabled={isDisabled}
									placeholder={strings.writeWordHere}
									spellCheck={false}
									autoComplete="off"
									type="text"
									autoCorrect="off"
									/* keyBoardType="visible-password" */
									autoCapitalize="off"
									onChange={(ev) => {
										if (isDisabled) {
											return;
										}
										const value = ev.target.value;
										if (value.trim() !== '')
											this.setResults(
												assignmentId,
												value
											);
									}}
									onFocus={() => {
										const disabledAssignments =
											this.getDisabledAssignments();
										disabledAssignments[
											assignmentId
										] = false;

										if (assignmentAudioPlayCount === 0) {
											audioPlayerRef.handleStartPlayer();
										}

										this.setState({
											disabledAssignments,
										});
									}}
									onClick={() => {
										this.checkForConsecutiveMistake(
											assignmentId
										);
									}}
									onKeyUp={(e) => {
										if (
											e.keyCode === 9 ||
											e.code === 'Tab'
										) {
											this.checkForConsecutiveMistake(
												assignmentId
											);
										}
									}}
								/>
							</div>
						);
					})}

				{/* Creating new view for Tablets */}

				{!this.state.bruteFinish &&
					isTablet &&
					assignments.map((assignment, index) => {
						const assignmentId = assignment.id;
						const mainIndex = index + 1;
						const audioFile = this.audioFiles[assignmentId];
						const assignmentAudioPlayCount =
							audioPlayCounts[assignmentId] || 0;
						const isDisabled = disabledAssignments[assignmentId];
						const isAudioDisabled =
							isDisabled ||
							assignmentAudioPlayCount === maxAudioPlayCount;

						let audioPlayerRef;
						return (
							<div
								style={{
									display: 'flex',
									marginTop: '50px',
								}}
								className={classNames(
									'lit-input lit-revealed',
									{
										'lit-input--disabled': isDisabled,
									}
								)}
								key={assignmentId}
							>
								<div
									className=""
									style={{
										height: '50px',
										width: '50px',
										marginRight: '20px',
									}}
								>
									{this.state.showQuestionPlayButton && (
										<FontAwesomeIcon
											className={
												!isAudioDisabled
													? 'lit-play-button-ordiktat'
													: 'lit-play-button-ordiktat-disabled '
											}
											size="lg"
											icon={faVolumeHigh}
											onClick={() => {
												if (!isAudioDisabled) {
													this.setState({
														showWordAudio: true,
														showQuestionPlayButton: false,
														currentClickedIndex:
															mainIndex,
														showChoicePlayButton: false,
													});
												}
											}}
										/>
									)}
									{!this.state.showQuestionPlayButton &&
										this.state.currentClickedIndex !==
											mainIndex && (
											<FontAwesomeIcon
												size="lg"
												className="lit-play-button-ordiktat-disabled"
												icon={faVolumeHigh}
											/>
										)}
									{this.state.showWordAudio &&
										this.state.currentClickedIndex ===
											mainIndex && (
											<div>
												<AudioPlayer
													disabled={isAudioDisabled}
													id={assignmentId}
													autoplay
													onComplete={() => {
														this.setState({
															showQuestionPlayButton: true,
															currentClickedIndex:
																null,
															showChoicePlayButton: true,
														});
													}}
													onStart={() => {
														this.setState({
															audioPlayCounts:
																updateObject(
																	audioPlayCounts,
																	{
																		[assignmentId]:
																			assignmentAudioPlayCount +
																			1,
																	}
																),
														});
													}}
													ref={(ref) => {
														audioPlayerRef = ref;
													}}
													refs={refs}
													src={audioFile}
												/>{' '}
											</div>
										)}
								</div>
								<input
									className="lit-input__field"
									disabled={isDisabled}
									placeholder={strings.writeWordHere}
									spellCheck={false}
									autoComplete="off"
									type="text"
									autoCorrect="off"
									/* keyBoardType="visible-password" */
									autoCapitalize="off"
									onChange={(ev) => {
										if (isDisabled) {
											return;
										}
										const value = ev.target.value;
										if (value.trim() !== '')
											this.setResults(
												assignmentId,
												value
											);
									}}
									onFocus={() => {
										const disabledAssignments =
											this.getDisabledAssignments();
										disabledAssignments[
											assignmentId
										] = false;

										/* if (assignmentAudioPlayCount === 0) {
											audioPlayerRef.handleStartPlayer();
										} */
										if (!isAudioDisabled) {
											this.setState({
												showWordAudio: true,
												showQuestionPlayButton: false,
												currentClickedIndex: mainIndex,
												showChoicePlayButton: false,
											});
										}

										this.setState({
											disabledAssignments,
										});
									}}
									onClick={() => {
										this.checkForConsecutiveMistake(
											assignmentId
										);
									}}
									onKeyUp={(e) => {
										if (
											e.keyCode === 9 ||
											e.code === 'Tab'
										) {
											this.checkForConsecutiveMistake(
												assignmentId
											);
										}
									}}
								/>
							</div>
						);
					})}
				{(isNextButtonVisible || this.state.bruteFinish) && (
					<button
						className="lit-btn lit bg-primary"
						onClick={() => onNextStep(results)}
						disabled={isSendingTestResults}
					>
						{strings.done}
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		testStepAnswers: {isSendingTestResults},
	} = state;

	return {
		isSendingTestResults,
	};
};

export default connect(mapStateToProps)(Test);
